@import "../../../styles/variables.scss";

.OperatingLimitsOverview__group__header {
  word-wrap: normal;
  border-bottom: 1px solid $color-gray-lighter;
  color: $color-gray;
  &:hover,
  &:focus {
    background-color: $color-aqua-lightest;
  }
}

.level-toggler + span {
  &[data-level="1"] {
    color: #7fcdbb;
  }

  &[data-level="2"] {
    color: #8856a7;
  }

  &[data-level="3"] {
    color: #ffcc66;
  }

  &[data-level="4"] {
    color: #d0147e;
  }

  &[data-level="5"] {
    color: #253494;
  }
}

.level-toggler {
  &[data-level="1"]:checked {
    background-color: #7fcdbb;
    border: solid #7fcdbb;
  }

  &[data-level="2"]:checked {
    background-color: #8856a7;
    border: solid #8856a7;
  }

  &[data-level="3"]:checked {
    background-color: #ffcc66;
    border: solid #ffcc66;
  }

  &[data-level="4"]:checked {
    background-color: #d0147e;
    border: solid #d0147e;
  }

  &[data-level="5"]:checked {
    background-color: #253494;
    border: solid #253494;
  }
}
