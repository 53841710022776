.Error {
  width: 100%;
  padding-top: 3em;

  color: red;
  text-align: center;
  font-size: 1rem;
}

.Error__label {
  display: inline-block;
  margin-left: 0.1em;
  font-size: 1.5em;
}

.Error__icon {
  display: block;
  font-size: 3em;
  padding-bottom: 0.2em;
}
