.brush__triangle {
  --r: 3px; /* border radius */

  height: 13px;
  aspect-ratio: 2;
  --_g: calc(var(--r) / tan(22.5deg)) top var(--r), #000 98%, #0000 101%;
  -webkit-mask:
    conic-gradient(
        from 157.5deg at 50%
          calc(var(--r) / (3 * sqrt(2) - 4) - 100% / tan(22.5deg)),
        #000 45deg,
        #0000 0
      )
      0 0/100% calc(100% - var(--r) / sqrt(2)) no-repeat,
    radial-gradient(
      var(--r) at 50% calc(100% - var(--r) * sqrt(2)),
      #000 98%,
      #0000 101%
    ),
    radial-gradient(var(--r) at left var(--_g)),
    radial-gradient(var(--r) at right var(--_g));
  clip-path: polygon(50% 100%, 100% 0, 0 0);
}
