.BarChart {
  width: 100%;
  height: 100%;
  padding-bottom: 0.5em;
}

.BarChart__title {
  font-size: 0.8em;
  padding: 0.5em;
  border-bottom: 1px solid #dadada;
  text-transform: uppercase;
  color: #555555;
}

.BarChart__chart {
  position: relative;
  height: calc(100% - 2.5em);
  width: calc(100% - 5em);

  margin-top: 1em;
  margin-left: 1em;
}
.BarChart__chart-full-width {
  width: calc(100% - 3em);
  height: calc(100% - 1.5em);
  margin-top: 0px;
}

.BarChart__chart--withTitle {
  /* Account for title height */
  height: calc(100% - 2.5em - 2.5em);
}

.BarChart__plot {
  width: 100%;
  height: 100%;

  // moved to BarChart.js to make customizable
  // .datapoint rect {
  //     &[data-index="1"] {
  //         fill: #7FCDBB;
  //     }

  //     &[data-index="2"] {
  //         fill: #8856A7;
  //     }

  //     &[data-index="3"] {
  //         fill: #FFCC66;
  //     }

  //     &[data-index="4"] {
  //         fill: #D0147E;
  //     }

  //     &[data-index="5"] {
  //         fill: #253494;
  //     }

  //     &[data-index="6"] {
  //         fill: #c7e9b4;
  //     }
  // }
}

.BarChart__selectedIndex {
  fill: transparent;
  stroke-width: 3px;
  stroke: black;

  /* Avoid cutting off the bottom of the outline. */
  transform: translate(0, -1.5px);
}

.BarChart__selectedRange {
  // fill: transparent;
  // stroke-width: 3px;
  // stroke: black;

  fill: #dad9d9;
  /* Avoid cutting off the bottom of the outline. */
  // transform: translate(0, -1.5px);
}

.BarChart__yAxis {
  width: 4em;
  position: absolute;
  left: 100%;
  z-index: 0;
}

.BarChart__xAxis {
  height: 2em;
  position: absolute;
  top: 100%;

  /* This isn't BEM, but that's because D3 isn't really BEM. When we're extending
     * D3 classes, we need to follow its conventions instead. */
  .domain {
    stroke: none;
  }
}

.BarChart__select {
  position: absolute;
  z-index: 10000;
}

.BarChart__hover {
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;
}

.BarChart__hover__entry {
  position: absolute;

  &:hover {
    .BarChart__hover__label {
      display: block;
    }
  }
}

.BarChart__hover__label {
  z-index: 1000;
  display: none;
  position: absolute;
  text-align: center;
  pointer-events: none;
  white-space: nowrap;

  box-shadow: 0 0 3px #00000022;
  background-color: #222222;
  color: #fefefe;

  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.15em;
  padding-bottom: 0.15em;

  border-radius: 3px;
  min-width: 2.5em;

  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  &:after {
    content: " ";
    display: block;
    position: absolute;
    bottom: -0.8rem;
    left: calc(50% - 0.8rem);
    width: 0.8rem;
    height: 0.8rem;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: 0.8rem solid #222222;
  }
}
