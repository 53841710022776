.PB_MAX_HEIGHT_SIDEBAR {
  max-height: calc(100vh - 6rem);
  overflow-y: scroll;
}

.DRAWER {
  height: calc(100vh - 6rem); // the nav bar is 3rem
}

// .FILL_SCREEN_MINUS_NAV {
//   height: calc(100vh - 3rem); // the nav bar is 3rem
// }
