@use "../../common/utilities";

.striped3 {
  background: repeating-linear-gradient(
    135deg,
    utilities.$anom3 2px,
    utilities.$anom3 5px,
    #999999 5px,
    #999999 7px
  );
}
.striped2 {
  background: repeating-linear-gradient(
    135deg,
    utilities.$anom2 2px,
    utilities.$anom2 5px,
    #999999 5px,
    #999999 7px
  );
}
.striped1 {
  background: repeating-linear-gradient(
    135deg,
    utilities.$anom1 2px,
    utilities.$anom1 5px,
    #999999 5px,
    #999999 7px
  );
}
.striped0 {
  background: repeating-linear-gradient(
    135deg,
    utilities.$anom0 2px,
    utilities.$anom0 5px,
    #999999 5px,
    #999999 7px
  );
}
