.MainLoader {
  width: 100%;
  font-size: 1rem;
  padding-top: 3em;
  text-align: center;
}

.MainLoader__label {
  font-size: 1em;
  margin-left: 0.1em;
  display: inline-block;
}

.MainLoader__icon {
  font-size: 1.25em;
  display: inline-block;
}
