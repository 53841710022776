// .MIN_HEIGHT_TO_HIDE_FOOTER {
//   min-height: calc(100vh - 6rem);
// }

.MIN_H_SCREEN {
  min-height: calc(100vh - 3rem);
}

.MIN_H_SCREEN2 {
  min-height: calc(100vh - 6rem);
}

.DRI_SIDEBAR_WIDTH {
  width: 24rem;
}

// values should match colors.js

.checkbox-green {
  --chkbg: rgb(136 196 37);
  --chkfg: rgb(240 253 244);
  --border-opacity: 1;
  border-color: rgb(136 196 37);
}
.checkbox-green:hover {
  --border-opacity: 1;
  border-color: rgb(136 196 37);
}
.checkbox-green:focus-visible {
  outline: 2px solid rgb(136 196 37);
}
.checkbox-green:checked,
.checkbox-green[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(136 196 37);
  --bg-opacity: 1;
  background-color: rgb(136 196 37);
  --text-opacity: 1;
  color: rgb(240 253 244);
}
.checkbox-green:checked,
.checkbox-green[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(136 196 37);
  --bg-opacity: 1;
  background-color: rgb(136 196 37);
  --text-opacity: 1;
  color: rgb(240 253 244);
}

.checkbox-red {
  --chkbg: rgb(194 26 1);
  --chkfg: rgb(254 242 242);
  --border-opacity: 1;
  border-color: rgb(194 26 1);
}
.checkbox-red:hover {
  --border-opacity: 1;
  border-color: rgb(194 26 1);
}
.checkbox-red:focus-visible {
  outline: 2px solid rgb(194 26 1);
}
.checkbox-red:checked,
.checkbox-red[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(194 26 1);
  --bg-opacity: 1;
  background-color: rgb(194 26 1);
  --text-opacity: 1;
  color: rgb(254 242 242);
}
.checkbox-red:checked,
.checkbox-red[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(194 26 1);
  --bg-opacity: 1;
  background-color: rgb(194 26 1);
  --text-opacity: 1;
  color: rgb(254 242 242);
}

.checkbox-orange {
  --chkbg: rgb(233 127 2);
  --chkfg: rgb(255 247 237);
  --border-opacity: 1;
  border-color: rgb(233 127 2);
}
.checkbox-orange:hover {
  --border-opacity: 1;
  border-color: rgb(233 127 2);
}
.checkbox-orange:focus-visible {
  outline: 2px solid rgb(233 127 2);
}
.checkbox-orange:checked,
.checkbox-orange[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(233 127 2);
  --bg-opacity: 1;
  background-color: rgb(233 127 2);
  --text-opacity: 1;
  color: rgb(255 247 237);
}
.checkbox-orange:checked,
.checkbox-orange[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(233 127 2);
  --bg-opacity: 1;
  background-color: rgb(233 127 2);
  --text-opacity: 1;
  color: rgb(255 247 237);
}

.checkbox-yellow {
  --chkbg: rgb(249 202 0);
  --chkfg: rgb(254 252 232);
  --border-opacity: 1;
  border-color: rgb(249 202 0);
}
.checkbox-yellow:hover {
  --border-opacity: 1;
  border-color: rgb(249 202 0);
}
.checkbox-yellow:focus-visible {
  outline: 2px solid rgb(249 202 0);
}
.checkbox-yellow:checked,
.checkbox-yellow[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(249 202 0);
  --bg-opacity: 1;
  background-color: rgb(249 202 0);
  --text-opacity: 1;
  color: rgb(254 252 232);
}
.checkbox-yellow:checked,
.checkbox-yellow[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(249 202 0);
  --bg-opacity: 1;
  background-color: rgb(249 202 0);
  --text-opacity: 1;
  color: rgb(254 252 232);
}


.aria-checkbox-green {
  --chkbg: rgb(213, 179, 245);
  --chkfg: rgb(240 253 244);
  --border-opacity: 1;
  border-color: rgb(213, 179, 245);
}
.aria-checkbox-green:hover {
  --border-opacity: 1;
  border-color: rgb(213, 179, 245);
}
.aria-checkbox-green:focus-visible {
  outline: 2px solid rgb(213, 179, 245);
}
.aria-checkbox-green:checked,
.aria-checkbox-green[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(213, 179, 245);
  --bg-opacity: 1;
  background-color: rgb(213, 179, 245);
  --text-opacity: 1;
  color: rgb(240 253 244);
}
.aria-checkbox-green:checked,
.aria-checkbox-green[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(213, 179, 245);
  --bg-opacity: 1;
  background-color: rgb(213, 179, 245);
  --text-opacity: 1;
  color: rgb(240 253 244);
}

.aria-checkbox-red {
  --chkbg: rgb(85, 20, 122);
  --chkfg: rgb(213, 179, 244);
  --border-opacity: 1;
  border-color: rgb(85, 20, 122);
}
.aria-checkbox-red:hover {
  --border-opacity: 1;
  border-color: rgb(85, 20, 122);
}
.aria-checkbox-red:focus-visible {
  outline: 2px solid rgb(85, 20, 122);
}
.aria-checkbox-red:checked,
.aria-checkbox-red[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(85, 20, 122);
  --bg-opacity: 1;
  background-color: rgb(85, 20, 122);
  --text-opacity: 1;
  color: rgb(213, 179, 244);
}
.aria-checkbox-red:checked,
.aria-checkbox-red[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(85, 20, 122);
  --bg-opacity: 1;
  background-color: rgb(85, 20, 122);
  --text-opacity: 1;
  color: rgb(213, 179, 244);
}

.aria-checkbox-orange {
  --chkbg: rgb(158, 65, 220);
  --chkfg: rgb(182, 75, 254);
  --border-opacity: 1;
  border-color: rgb(158, 65, 220);
}
.aria-checkbox-orange:hover {
  --border-opacity: 1;
  border-color: rgb(158, 65, 220);
}
.aria-checkbox-orange:focus-visible {
  outline: 2px solid rgb(158, 65, 220);
}
.aria-checkbox-orange:checked,
.aria-checkbox-orange[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(158, 65, 220);
  --bg-opacity: 1;
  background-color: rgb(158, 65, 220);
  --text-opacity: 1;
  color: rgb(182, 75, 254);
}
.aria-checkbox-orange:checked,
.aria-checkbox-orange[checked="true"] {
  --border-opacity: 1;
  border-color: rgb(158, 65, 220);
  --bg-opacity: 1;
  background-color: rgb(158, 65, 220);
  --text-opacity: 1;
  color: rgb(182, 75, 254);
}