@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@radix-ui/colors/red.css";
@import "@radix-ui/colors/red-dark.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/green-dark.css";
@import "@radix-ui/colors/indigo.css";
@import "@radix-ui/colors/indigo-dark.css";
@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/slate-dark.css";
@import "@radix-ui/colors/amber.css";
@import "@radix-ui/colors/amber-dark.css";
@import "@radix-ui/colors/orange.css";
@import "@radix-ui/colors/orange-dark.css";
@import "@radix-ui/colors/blue.css";
@import "@radix-ui/colors/blue-dark.css";
@import "@radix-ui/colors/violet.css";
@import "@radix-ui/colors/violet-dark.css";

@layer base {
  :root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --anom-red-1: #fffcfc;
    --anom-red-2: #fff8f6;
    --anom-red-3: #feece8;
    --anom-red-4: #ffdcd5;
    --anom-red-5: #ffcec5;
    --anom-red-6: #fdbeb4;
    --anom-red-7: #f4aa9e;
    --anom-red-8: #eb9082;
    --anom-red-9: #c01c12;
    --anom-red-10: #af0000;
    --anom-red-11: #ce2e22;
    --anom-red-12: #641912;

    --anom-red-a-1: #ff000003;
    --anom-red-a-2: #ff390009;
    --anom-red-a-3: #f42d0017;
    --anom-red-a-4: #ff2b002a;
    --anom-red-a-5: #ff28003a;
    --anom-red-a-6: #f923014b;
    --anom-red-a-7: #e3200061;
    --anom-red-a-8: #d71d007d;
    --anom-red-a-9: #bb0b00ed;
    --anom-red-a-10: #af0000;
    --anom-red-a-11: #c70e00dd;
    --anom-red-a-12: #580800ed;

    --anom-orange-1: #fefcfb;
    --anom-orange-2: #fff7f1;
    --anom-orange-3: #ffeddf;
    --anom-orange-4: #ffdbbe;
    --anom-orange-5: #ffcea7;
    --anom-orange-6: #fdc299;
    --anom-orange-7: #edb086;
    --anom-orange-8: #e19a67;
    --anom-orange-9: #e77f21;
    --anom-orange-10: #db7200;
    --anom-orange-11: #c05d00;
    --anom-orange-12: #52321a;

    --anom-orange-a-1: #c0400004;
    --anom-orange-a-2: #ff6e000e;
    --anom-orange-a-3: #ff700020;
    --anom-orange-a-4: #ff720041;
    --anom-orange-a-5: #ff720058;
    --anom-orange-a-6: #fa670066;
    --anom-orange-a-7: #da590079;
    --anom-orange-a-8: #cd560098;
    --anom-orange-a-9: #e46c00de;
    --anom-orange-a-10: #db7200;
    --anom-orange-a-11: #c05d00;
    --anom-orange-a-12: #3e1b00e5;

    --anom-yellow-1: #fefdfb;
    --anom-yellow-2: #fffaea;
    --anom-yellow-3: #fff4c0;
    --anom-yellow-4: #ffeb9d;
    --anom-yellow-5: #ffe17b;
    --anom-yellow-6: #f6d576;
    --anom-yellow-7: #e4c46b;
    --anom-yellow-8: #d2ad40;
    --anom-yellow-9: #ffd01d;
    --anom-yellow-10: #f6c72e;
    --anom-yellow-11: #967200;
    --anom-yellow-12: #443b20;

    --anom-yellow-a-1: #c0800004;
    --anom-yellow-a-2: #ffc30015;
    --anom-yellow-a-3: #ffd3003f;
    --anom-yellow-a-4: #ffcb0062;
    --anom-yellow-a-5: #ffc60084;
    --anom-yellow-a-6: #efb10089;
    --anom-yellow-a-7: #d19a0094;
    --anom-yellow-a-8: #c39200bf;
    --anom-yellow-a-9: #ffca00e2;
    --anom-yellow-a-10: #f4bb00d1;
    --anom-yellow-a-11: #967200;
    --anom-yellow-a-12: #291f00df;

    --anom-green-1: #fbfdf9;
    --anom-green-2: #f6fbf1;
    --anom-green-3: #e5fad0;
    --anom-green-4: #d4f6b1;
    --anom-green-5: #c3ed96;
    --anom-green-6: #b2e17c;
    --anom-green-7: #9ccf5c;
    --anom-green-8: #7fbb13;
    --anom-green-9: #87c425;
    --anom-green-10: #7cb90b;
    --anom-green-11: #518000;
    --anom-green-12: #2e4310;

    --anom-green-a-1: #55aa0006;
    --anom-green-a-2: #5cb7000e;
    --anom-green-a-3: #72e4002f;
    --anom-green-a-4: #73e2004e;
    --anom-green-a-5: #6ed40069;
    --anom-green-a-6: #6ac50083;
    --anom-green-a-7: #64b400a3;
    --anom-green-a-8: #75b600ec;
    --anom-green-a-9: #73ba00da;
    --anom-green-a-10: #76b600f4;
    --anom-green-a-11: #518000;
    --anom-green-a-12: #203600ef;

    --aria-1: #fefcff;
    --aria-2: #fbf7ff;
    --aria-3: #f7ecff;
    --aria-4: #f2e0ff;
    --aria-5: #ebd2ff;
    --aria-6: #e1c1ff;
    --aria-7: #d4a9fe;
    --aria-8: #c48af9;
    --aria-9: #b076e3;
    --aria-10: #a46ad6;
    --aria-11: #8147b0;
    --aria-12: #480f6c;

    --aria-a-1: #aa00ff03;
    --aria-a-2: #8000ff08;
    --aria-a-3: #9400ff13;
    --aria-a-4: #9500ff1f;
    --aria-a-5: #8e00ff2d;
    --aria-a-6: #8400ff3e;
    --aria-a-7: #8000fd56;
    --aria-a-8: #7f00f275;
    --aria-a-9: #6c00cb89;
    --aria-a-10: #6400b995;
    --aria-a-11: #510092b8;
    --aria-a-12: #3d0063f0;

    --ft-active: 0 113 188;

    --anom0: 136 196 37;
    --anom1: 248 202 0;
    --anom2: 233 127 2;
    --anom3: 194 26 1;

    --background: 0 0% 95%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --xprimary: 222.2 47.4% 11.2%;
    --xprimary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --red-1: #180e0c;
    --red-2: #22120f;
    --red-3: #420905;
    --red-4: #5b0000;
    --red-5: #6d0000;
    --red-6: #800a05;
    --red-7: #9c1e14;
    --red-8: #c9281d;
    --red-9: #c01c12;
    --red-10: #a2251b;
    --red-11: #ff907f;
    --red-12: #ffcec3;

    --red-a1: #f1000008;
    --red-a2: #f61f0013;
    --red-a3: #fd000035;
    --red-a4: #fd000050;
    --red-a5: #fe000063;
    --red-a6: #ff030077;
    --red-a7: #ff281795;
    --red-a8: #fe2f20c6;
    --red-a9: #ff2013bc;
    --red-a10: #fe31219c;
    --red-a11: #ff907f;
    --red-a12: #ffcec3;

    --anom-orange-1: #15100d;
    --anom-orange-2: #1e1611;
    --anom-orange-3: #311e10;
    --anom-orange-4: #452204;
    --anom-orange-5: #522b0a;
    --anom-orange-6: #613919;
    --anom-orange-7: #784a27;
    --anom-orange-8: #995f32;
    --anom-orange-9: #e77f21;
    --anom-orange-10: #da7307;
    --anom-orange-11: #ffa561;
    --anom-orange-12: #fedfcb;

    --anom-orange-a-1: #de000005;
    --anom-orange-a-2: #fe6d120e;
    --anom-orange-a-3: #fb700a23;
    --anom-orange-a-4: #fe5f0038;
    --anom-orange-a-5: #fe700046;
    --anom-orange-a-6: #ff882956;
    --anom-orange-a-7: #fd94436f;
    --anom-orange-a-8: #ff9a4b92;
    --anom-orange-a-9: #fe8b22e6;
    --anom-orange-a-10: #fe8406d8;
    --anom-orange-a-11: #ffa561;
    --anom-orange-a-12: #ffe0ccfe;

    --anom-yellow-1: #13110b;
    --anom-yellow-2: #1b1810;
    --anom-yellow-3: #2b220a;
    --anom-yellow-4: #3a2b00;
    --anom-yellow-5: #463400;
    --anom-yellow-6: #53420b;
    --anom-yellow-7: #66541e;
    --anom-yellow-8: #826b28;
    --anom-yellow-9: #f7c830;
    --anom-yellow-10: #ecbe1c;
    --anom-yellow-11: #fecf3b;
    --anom-yellow-12: #fbe8b7;

    --anom-yellow-a-1: #bb110003;
    --anom-yellow-a-2: #f9b4000b;
    --anom-yellow-a-3: #feac001c;
    --anom-yellow-a-4: #ffa8002c;
    --anom-yellow-a-5: #ffae0039;
    --anom-yellow-a-6: #ffc10047;
    --anom-yellow-a-7: #fdcb365c;
    --anom-yellow-a-8: #fdcd417a;
    --anom-yellow-a-9: #fecd31f7;
    --anom-yellow-a-10: #ffcd1deb;
    --anom-yellow-a-11: #ffd03bfe;
    --anom-yellow-a-12: #ffecbafb;

    --anom-green-1: #0e130a;
    --anom-green-2: #151a11;
    --anom-green-3: #1f2914;
    --anom-green-4: #27390f;
    --anom-green-5: #314712;
    --anom-green-6: #3b5615;
    --anom-green-7: #466617;
    --anom-green-8: #517816;
    --anom-green-9: #87c425;
    --anom-green-10: #7bb900;
    --anom-green-11: #90cd33;
    --anom-green-12: #ccf0a6;

    --anom-green-a-1: #00bb0003;
    --anom-green-a-2: #78f7120a;
    --anom-green-a-3: #9bfd2f1a;
    --anom-green-a-4: #94ff062b;
    --anom-green-a-5: #9eff163a;
    --anom-green-a-6: #a2ff1f4a;
    --anom-green-a-7: #a4fd225c;
    --anom-green-a-8: #a4fd1c6f;
    --anom-green-a-9: #aeff2cc0;
    --anom-green-a-10: #a7ff00b4;
    --anom-green-a-11: #b1fe3bca;
    --anom-green-a-12: #d9ffb0ef;

    --aria-1: #140e19;
    --aria-2: #1c1523;
    --aria-3: #2d1d3c;
    --aria-4: #3a254b;
    --aria-5: #432f55;
    --aria-6: #4f3a61;
    --aria-7: #604a73;
    --aria-8: #79638d;
    --aria-9: #ecdcfc;
    --aria-10: #e2d2f2;
    --aria-11: #c5addb;
    --aria-12: #eadafa;

    --aria-a-1: #6700f409;
    --aria-a-2: #9e44f714;
    --aria-a-3: #a953fb2f;
    --aria-a-4: #b762fc3f;
    --aria-a-5: #c07aff49;
    --aria-a-6: #c98bff56;
    --aria-a-7: #d19cff69;
    --aria-a-8: #d9afff85;
    --aria-a-9: #efdffffc;
    --aria-a-10: #eddcfef2;
    --aria-a-11: #e4c8fed9;
    --aria-a-12: #efdefffa;

    --background: 240 10% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --xprimary: 210 40% 98%;
    --xprimary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  body,
  html {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

@supports (font-variation-settings: normal) {
  html,
  body {
    font-family: "Open Sans", sans-serif;
    font-variation-settings: "wght" 400;
  }
}

/* Some icons bundled with react-icons don't like to play nice with inheriting colors, specifically the Gr collection */
.REACT_ICON > path {
  stroke: inherit;
}

html {
  scroll-behavior: smooth;
}
html,
body {
  margin: 0;
  padding: 0;

  font-weight: 400;
}

.mono-500 {
  font-weight: 500;
}

.mono-300 {
  font-weight: 300;
}

.mono {
  font-family: "Roboto Mono", monospace;
}

body {
  min-height: 100vh;
}

.background {
  min-width: 100vw;
}

#root {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

#logo {
  height: 1.4em;
}

#nmm-unitList {
  background-color: white;
}

.trendChart {
  width: 100%;
  height: 100%;
}

/* airbnb react calendar style override */

.node circle {
  fill: #999;
}

.node text {
  font: 10px sans-serif;
}

.node--internal circle {
  fill: #555;
}

.node--internal text {
  text-shadow:
    0 1px 0 #fff,
    0 -1px 0 #fff,
    1px 0 0 #fff,
    -1px 0 0 #fff;
}

.link {
  fill: none;
  stroke: #555;
  stroke-opacity: 0.4;
  stroke-width: 1.5px;
}

label {
  display: block;
}

.hiddenDraggable {
  overflow: hidden !important;
}

.faultTree-overview-header-wrapper {
  font-size: 0.9em;
}

.faultTree-statusSeries-item-wrapper {
  display: inline-block;
  text-align: center;
  height: 35px;
  float: left;
}

.faultTree-statusSeries-item-status {
  border-radius: 8px;
  width: 100%;
  height: 16px;
  display: block;
  border: solid 0px #f7f7f7;
}

.faultTree-statusSeries-item-status.active {
  background-color: #0071bc;
  border: solid 1px #0071bc;
}
.faultTree-statusSeries-item-status.inactive {
  background-color: #d9d9d9;
  border: solid 1px #d9d9d9;
}
.faultTree-statusSeries-item-status.activeShutdown {
  border: solid 1px #0071bc;
  background-image: repeating-linear-gradient(
    145deg,
    #0071bc,
    #0071bc 10px,
    #999 10px,
    #999 20px
  );
}
.faultTree-statusSeries-item-status.inactiveShutdown {
  border: solid 1px #d9d9d9;
  background-image: repeating-linear-gradient(
    145deg,
    #d9d9d9,
    #d9d9d9 10px,
    #999 10px,
    #999 20px
  );
}
.faultTree-statusSeries-item-status.error {
  background-color: #de0b07;
  border: solid 1px #de0b07;
}

.faultTree-details-header {
  background-color: red;
  height: 80px;
}

.faultTree-details-header-treeDropdown {
  width: 250px;
}

.faultTree-details-header-treeDropdown-container {
  font-family: "Open Sans", sans-serif;
  background-color: #f4f4f4;
  border: solid 1px #bbb;
  border-radius: 4px;
  padding-left: 10px;
  height: 31px;
  font-size: 1rem;
  padding-top: 1px;
}

.faultTree-details-header-treeDropdown-container:hover {
  background-color: #fcfcfc;
}

.faultTree-details-header-treeDropdown-selected {
  padding-left: 8px;
  height: 23px;
  width: 100%;
  overflow: hidden;
}

.faultTree-details-header-treeDropdown-selected-wrapper {
  overflow: hidden;
  height: 100%;
  padding-top: 4px;
  min-width: 230px;
}

.faultTree-details-header-treeDropdown-item {
  height: 100%;
}

.faultTree-chartHeader-wrapper {
  border: solid 1px #ddd;
  background-color: #fbfbfb;
  border-radius: 3px;
  width: calc(100% - 2rem);
  box-sizing: border-box;
  margin-bottom: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.faultTree-nodeDetail-flex {
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
}

.faultTree-nodeDetail-full {
  /*display: flex;*/
  margin-left: 1rem;
  margin-right: 1rem;
}

.faultTree-chart-wrapper {
  border: solid 1px #ddd;
  border-radius: 3px;
  background-color: #fbfbfb;
  width: 49.5%;
  height: 648px;
  margin-right: 0.5%;
  position: relative;
  justify-content: flex-start;
}

.faultTree-chart-wrapper-full {
  border: solid 1px #ddd;
  border-radius: 3px;
  background-color: #fbfbfb;
  width: 100%;
  height: 648px;
  margin-right: 0.5%;
  position: relative;
  margin-bottom: 0.3%;
}

.faultTree-nodeDetails-wrapper {
  width: 50%;
  background-color: #fbfbfb;
  border: solid 1px #ddd;
  border-radius: 3px;
  justify-content: flex-end;
  font-size: 1.2rem;
}

.faultTree-nodeDetails-wrapper-full {
  width: 100%;
  background-color: #fbfbfb;
  border: solid 1px #ddd;
  border-radius: 3px;
  justify-content: flex-end;
  font-size: 1.2rem;
}

.faultTree-chartHeader-name {
  height: 100%;
  color: #444;
  float: left;
  font-size: 1.1em;
}

.faultTree-chartHeader-name div {
  overflow: hidden;
  display: inline-block;
}

.faultTree-chartHeader-status {
  height: 100%;
  font-size: 0.8em;
}

.faultTree-nodeDetails-status-icon {
  font-size: 12px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
  padding: 3px 0.5em;
  margin: 0 10px;
}

.faultTree-nodeDetails-status-icon.active {
  background-color: #0071bc;
  color: #ffffff;
  font-weight: bold;
  border: solid 1px #0b51ba;
}

.faultTree-nodeDetails-status-icon.inactive {
  background-color: #efefef;
  color: #777777;
  font-weight: bold;
  border: solid 1px #dddddd;
}

.faultTree-nodeDetails-status-icon.processing {
  background-color: #d67bea;
  color: #ffffff;
  font-weight: bold;
}

.faultTree-nodeDetails-status-icon.error {
  background-color: #de0b07;
  color: #ffffff;
  font-weight: bold;
}

.faultTree-statusSeries-wrapper {
  height: 100%;
}

.faultTree-nodeDetails-header {
  padding: 10px 0;
  display: inline-block;
}

.faultTree-nodeDetails-header-name {
  bottom: 4px;
  color: #222222;
}

.faultTree-nodeDetails-header-error {
  font-size: 0.9em;
  margin-left: 0.75em;
  margin-top: 0.5em;
  color: red;
}

.faultTree-nodeDetails-actions {
  display: "inline-block";
  float: "right";
  padding-top: 5;
  font-size: 14px;
}

.faultTree-nodeDetails-nodeStatusSeries {
  height: 4.25em;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  padding-top: 10px;
  font-size: 1rem;

  padding-right: 1.9em;
  padding-left: 1.6em;
}

.faultTree-nodeDetails-info {
  font-size: 0.9em;
  padding: 15px 10px;
}

.faultTree-nodeDetails-info-label {
  color: #999999;
}

.faultTree-nodeDetails-info-expression {
  padding: 2px 4px;
  /* font-size: 90%; */
  color: #0071bc;
  background-color: #e6f0f3;
  border-radius: 4px;
  margin-top: 0.5rem;
  /* font-family: monospace; */

  /* Remove collapsing behavior:
   *
   * If there's no text, we still want a minimum height for the field. We also
   * want our text to be centered. This is... tricky to do, because CSS. We
   * could use tables, but there are potential side-effects. So, as usual,
   * falling back on `flex` is the preferred solution. It's potentially
   * overkill, but collapsed textboxes just look really bad. */
  min-height: 2em;
  /* display: flex; */
  align-items: center;
}

.faultTree-nodeDetails-info-recommendation {
  padding-top: 5px;
  overflow-y: auto;
  padding-bottom: 7px;
  padding-right: 10px;
  white-space: pre-line;
}

.faultTree-nodeDetails-info-childNodes-wrapper {
  height: 100%;
}

.faultTree-statusSeries-item-date.hidden {
  display: none;
}

.faultTree-statusSeries-item-date {
  font-size: 9px;
  color: #999;
}

.faultTree-statusSeries-item-date-tick {
  font-size: 0.8em;
  position: relative;
  bottom: 8px;
  right: 1px;
  color: #888;
}

.chartOptions-content {
  position: absolute;
  color: #909090;
  border: solid 1px #f2f2f2;
  z-index: 10;
  width: 130px;
  padding: 5px;
  background-color: #fbfbfb;
  border-radius: 3px;
}

.chartOptions-item {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.chartOptions-item input[type="checkbox"] {
  margin-right: 4px;
}

.slide-up-fade-in {
  animation: slideUpFadeIn 0.1s ease 0.3s both;
}

@keyframes slideUpFadeIn {
  from {
    opacity: 0;
    transform: translateY(150px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
