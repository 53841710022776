svg text {
  font-size: 11px;
  font-weight: 500;
}

.WHY_DAN text {
  font-size: unset;
}

.xaxis .minor-tick-text {
  opacity: 0.6;
}
/* .hidden {
  opacity: 0;
  display: block;
  visibility: visible;
} */
.xaxis path,
.xaxis line {
  fill: none;
  stroke: #999999;
  shape-rendering: crispEdges;
}
.yaxis path,
.yaxis line {
  fill: none;
  stroke: #999999;
  shape-rendering: crispEdges;
}
.xaxis line.tick.minor {
  stroke-opacity: 0.3;
}
.xaxis line.tick.major {
  stroke-opacity: 0.5;
}
.yaxis line.tick.minor {
  stroke-opacity: 0.3;
}
.yaxis line.tick.major {
  stroke-opacity: 0.3;
}
.stack {
  cursor: pointer;
}
#stacked-bar-chart-area .xaxis {
  cursor: pointer;
}
.bar-0 {
  fill: #88c425;
  shape-rendering: crispEdges;
}
.bar-1 {
  fill: #f8ca00;
  shape-rendering: crispEdges;
}
.bar-2 {
  fill: #e97f02;
  shape-rendering: crispEdges;
}
.bar-3 {
  fill: #c21a01;
  shape-rendering: crispEdges;
}
.bar-gray {
  fill: #555555;
}
.bar-none {
  fill: none;
}
.bar-today {
  transition: 1s;
  fill: #999999;
  opacity: 0.4;
  shape-rendering: crispEdges;
}
.timeline-bar-today {
  transition: 1s;
  fill: #999999;
  opacity: 0.25;
  shape-rendering: crispEdges;
}
.stacked-bar-today {
  fill: none;
  stroke: #333333;
  shape-rendering: crispEdges;
  stroke-width: 2;
  opacity: 1;
}
.line {
  fill: none;
  stroke: #eeeeee;
  stroke-opacity: 1;
  stroke: #555555;
  stroke-width: 1;
}
.red-point {
  fill: #c21a01;
}
.orange-point {
  fill: #e97f02;
}
.yellow-point {
  fill: #f8ca00;
}
.green-point {
  fill: #88c425;
}
.black-point {
  fill: #555555;
}
.gray-point {
  fill: #d9d9d9;
}
.blue-point {
  fill: #5b9bd5;
  stroke: #1f4e79;
}
.blue-point-nofill {
  fill: #5b9bd5;
  stroke: #1f4e79;
  opacity: 0.5;
  stroke: none;
}
.gray-point-nofill {
  fill: #d9d9d9;
  opacity: 0.5;
  stroke: none;
}
.extent {
  fill: #ffc000 !important;
}
#line-chart-area .xaxis {
  cursor: pointer;
}
.previous-point {
  stroke-width: 1px;
  stroke-dasharray: 3, 3;
  fill-opacity: 0.1;
}
#trend-analysis-chart-area .hover,
#line-chart-area .hover,
#td-line-chart-area .hover,
.pb-trend-analysis-chart-area .hover {
  opacity: 0.1;
}
#trend-analysis-chart-area .click-bar,
#line-chart-area .click-bar,
#td-line-chart-area .click-bar,
.pb-trend-analysis-chart-area .click-bar {
  cursor: pointer;
  opacity: 0;
}
.arc-text {
  fill: #555555 !important;
  font-size: 12px;
  font-weight: 500;
}
.arc-text-bg {
  fill: transparent;
  shape-rendering: crispEdges;
}
.arc path:not(.arc-highlight) {
  stroke: #ffffff;
}
.arc {
  cursor: pointer;
}
.arc-1 {
  fill: #88c425;
}
.arc-2 {
  fill: #f8ca00;
}
.arc-3 {
  fill: #e97f02;
}
.arc-4 {
  fill: #c21a01;
}
.arc-gray {
  fill: #555555;
  stroke-width: 0;
}
.arc-striped {
  stroke-width: 0;
}
.arc-outline {
  shape-rendering: geometricPrecision;
}
.no-arc-outline {
  stroke-width: 0;
}
.daily-hover {
  cursor: pointer;
  fill-opacity: 0.45;
  fill: #dadada;
}
.arc-highlight {
  stroke: #333333;
  stroke-width: 2;
  opacity: 1;
}
.semicircular-tick-line {
  stroke: #999999;
  fill: none;
}
.diagonalHatch path {
  stroke: #555555;
  stroke-width: 1.2;
}
.diagonalHatchBD {
  stroke-width: 0;
}
.hbar {
  color: #555555;
  margin-left: -3px;
  height: 22px;
  margin-bottom: -2px;
  margin-top: -1px;
}
.dri-today-text {
  font-size: 22px;
  font-weight: 200;
}
.dri-label-text {
  font-size: 9px;
}
.Transition-High {
  background-color: rgba(233, 127, 2, 0.7);
}
.Transition-Low {
  background-color: rgba(70, 130, 180, 0.7);
}
.high-bar {
  fill: #e97f02;
  stroke: none;
  shape-rendering: crispEdges;
}
.low-bar {
  fill: #00aaaa;
  stroke: none;
  shape-rendering: crispEdges;
}
path[class*="line low"] {
  stroke: #762a83;
  stroke-width: 2px;
}
path[class*="line high"] {
  stroke: #d8b365;
  stroke-width: 2px;
}
circle[class*="rank-high"] {
  fill: #d8b365;
  opacity: 1;
}
circle[class*="rank-low"] {
  fill: #762a83;
  opacity: 1;
}
g.series path.s1 {
  stroke-opacity: 0.6;
}
g.series path.s1 {
  stroke-opacity: 0.5;
}
g.series path.s3 {
  stroke-opacity: 0.4;
}
g.series path.s4 {
  stroke-opacity: 0.3;
}
circle.s1 {
  opacity: 0.4;
}
circle.s2 {
  opacity: 0.3;
}
circle.s3 {
  opacity: 0.2;
}
circle.s4 {
  opacity: 0.1;
}
.RAWOPC {
  fill: none;
  stroke-width: 1.2px;
  vector-effect: non-scaling-stroke;
}
.SD {
  fill: none;
  stroke-width: 1.3px;
  stroke: #555555;
  vector-effect: non-scaling-stroke;
}
.Level3HIGH,
.Level2HIGH,
.Level1HIGH {
  fill: none;
  stroke-width: 1.5px;
  vector-effect: non-scaling-stroke;
}
.Level3LOW,
.Level2LOW,
.Level1LOW {
  fill: none;
  stroke-width: 1.5px;
}
.ThresholdLOW,
.ThresholdHIGH {
  fill: none;
  stroke-width: 2px;
  stroke-dasharray: 2, 5;
  stroke: #555555;
}
.trend-hover {
  fill-opacity: 0.7;
  fill: #333333;
}
.hourFocusRect {
  fill: #000000;
}
rect.pane {
  fill: none;
  pointer-events: all;
}
.RAWOPC {
  fill: none;
  stroke-width: 1.2px;
  stroke: #0595d0;
}
.Level3HIGH,
.Level2HIGH,
.Level1HIGH,
.Level3LOW,
.Level2LOW,
.Level1LOW {
  fill: none;
  stroke-width: 1.5px;
  stroke: #762a83;
}
.RAWOPC.l0 {
  stroke: #0595d0;
  stroke-width: 1px;
}
.RAWOPC.l1 {
  stroke: #ff1493;
  stroke-width: 1px;
}
.RAWOPC.l2 {
  stroke: #ff9900;
  stroke-width: 1px;
}
.RAWOPC.l3 {
  stroke: #9900ff;
  stroke-width: 1px;
}
.RAWOPC.l4 {
  stroke: #37b000;
  stroke-width: 1px;
}
.RAWOPC.l5 {
  stroke: #04ffff;
  stroke-width: 1px;
}
.RAWOPC.l6 {
  stroke: #ff78f7;
  stroke-width: 1px;
}
.RAWOPC.l7 {
  stroke: #afb000;
  stroke-width: 1px;
}
.RAWOPC.l8 {
  stroke: #cb32ff;
  stroke-width: 1px;
}
.RAWOPC.l9 {
  stroke: #37ff64;
  stroke-width: 1px;
}
.RAWOPC.l10 {
  stroke: #7c9cdb;
  stroke-width: 1px;
}
.RAWOPC.l11 {
  stroke: #ffcb32;
  stroke-width: 1px;
}
.var-id--0:before {
  color: #0595d0;
}
.var-id--1:before {
  color: #ff1493;
}
.var-id--2:before {
  color: #ff9900;
}
.var-id--3:before {
  color: #9900ff;
}
.var-id--4:before {
  color: #37b000;
}
.var-id--5:before {
  color: #04ffff;
}
.var-id--6:before {
  color: #ff78f7;
}
.var-id--7:before {
  color: #afb000;
}
.var-id--8:before {
  color: #cb32ff;
}
.var-id--9:before {
  color: #37ff64;
}
.var-id--10:before {
  color: #7c9cdb;
}
.var-id--11:before {
  color: #ffcb32;
}
.var-id--disabled:before {
  color: #e0e0e0;
}
/*.Level3HIGH.l0,*/
/*.Level2HIGH.l0,*/
/*.Level1HIGH.l0,*/
/*.Level3LOW.l0,*/
/*.Level2LOW.l0,*/
/*.Level1LOW.l0 {*/
/*stroke: #004499;*/
/*}*/
/*.Level3HIGH.l1,*/
/*.Level2HIGH.l1,*/
/*.Level1HIGH.l1,*/
/*.Level3LOW.l1,*/
/*.Level2LOW.l1,*/
/*.Level1LOW.l1 {*/
/*stroke: #a10057;*/
/*}*/
/*.Level3HIGH.l2,*/
/*.Level2HIGH.l2,*/
/*.Level1HIGH.l2,*/
/*.Level3LOW.l2,*/
/*.Level2LOW.l2,*/
/*.Level1LOW.l2 {*/
/*stroke: #D65B00;*/
/*}*/
/*.Level3HIGH.l3,*/
/*.Level2HIGH.l3,*/
/*.Level1HIGH.l3,*/
/*.Level3LOW.l3,*/
/*.Level2LOW.l3,*/
/*.Level1LOW.l3 {*/
/*stroke: #581B91;*/
/*}*/
/*.Level3HIGH.l4,*/
/*.Level2HIGH.l4,*/
/*.Level1HIGH.l4,*/
/*.Level3LOW.l4,*/
/*.Level2LOW.l4,*/
/*.Level1LOW.l4 {*/
/*stroke: #274e13;*/
/*}*/
/*.Level3HIGH.l5,*/
/*.Level2HIGH.l5,*/
/*.Level1HIGH.l5,*/
/*.Level3LOW.l5,*/
/*.Level2LOW.l5,*/
/*.Level1LOW.l5 {*/
/*stroke: #00a8fd;*/
/*}*/
/*.Level3HIGH.l6,*/
/*.Level2HIGH.l6,*/
/*.Level1HIGH.l6,*/
/*.Level3LOW.l6,*/
/*.Level2LOW.l6,*/
/*.Level1LOW.l6 {*/
/*stroke: #a164bb;*/
/*}*/
/*.Level3HIGH.l7,*/
/*.Level2HIGH.l7,*/
/*.Level1HIGH.l7,*/
/*.Level3LOW.l7,*/
/*.Level2LOW.l7,*/
/*.Level1LOW.l7 {*/
/*stroke: #767600;*/
/*}*/
/*.Level3HIGH.l8,*/
/*.Level2HIGH.l8,*/
/*.Level1HIGH.l8,*/
/*.Level3LOW.l8,*/
/*.Level2LOW.l8,*/
/*.Level1LOW.l8 {*/
/*stroke: #8f32ff;*/
/*}*/
/*.Level3HIGH.l9,*/
/*.Level2HIGH.l9,*/
/*.Level1HIGH.l9,*/
/*.Level3LOW.l9,*/
/*.Level2LOW.l9,*/
/*.Level1LOW.l9 {*/
/*stroke: #27b277;*/
/*}*/
/*.Level3HIGH.l10,*/
/*.Level2HIGH.l10,*/
/*.Level1HIGH.l10,*/
/*.Level3LOW.l10,*/
/*.Level2LOW.l10,*/
/*.Level1LOW.l10 {*/
/*stroke: #260091;*/
/*}*/
/*.Level3HIGH.l11,*/
/*.Level2HIGH.l11,*/
/*.Level1HIGH.l11,*/
/*.Level3LOW.l11,*/
/*.Level2LOW.l11,*/
/*.Level1LOW.l11 {*/
/*stroke: #d6bf64;*/
/*}*/
.theme-1 .RAWOPC {
  stroke-width: 1px;
  opacity: 0.5;
}
.theme-1 .RAWOPC.l0 {
  stroke: #0595d0;
}
.theme-1 .RAWOPC.l1 {
  stroke: #0595d0;
}
.theme-1 .RAWOPC.l2 {
  stroke: #0595d0;
}
.theme-1 .RAWOPC.l3 {
  stroke: #0595d0;
}
.theme-1 .RAWOPC.l4 {
  stroke: #0595d0;
}
.theme-1 .Level3HIGH,
.theme-1 .Level2HIGH,
.theme-1 .Level1HIGH,
.theme-1 .Level3LOW,
.theme-1 .Level2LOW,
.theme-1 .Level1LOW {
  stroke-width: 1px;
  opacity: 0.5;
}
.theme-1 .Level3HIGH.l0,
.theme-1 .Level2HIGH.l0,
.theme-1 .Level1HIGH.l0,
.theme-1 .Level3LOW.l0,
.theme-1 .Level2LOW.l0,
.theme-1 .Level1LOW.l0 {
  stroke: #762a83;
}
.theme-1 .Level3HIGH.l1,
.theme-1 .Level2HIGH.l1,
.theme-1 .Level1HIGH.l1,
.theme-1 .Level3LOW.l1,
.theme-1 .Level2LOW.l1,
.theme-1 .Level1LOW.l1 {
  stroke: #762a83;
}
.theme-1 .Level3HIGH.l2,
.theme-1 .Level2HIGH.l2,
.theme-1 .Level1HIGH.l2,
.theme-1 .Level3LOW.l2,
.theme-1 .Level2LOW.l2,
.theme-1 .Level1LOW.l2 {
  stroke: #762a83;
}
.theme-1 .Level3HIGH.l3,
.theme-1 .Level2HIGH.l3,
.theme-1 .Level1HIGH.l3,
.theme-1 .Level3LOW.l3,
.theme-1 .Level2LOW.l3,
.theme-1 .Level1LOW.l3 {
  stroke: #762a83;
}
.theme-1 .Level3HIGH.l4,
.theme-1 .Level2HIGH.l4,
.theme-1 .Level1HIGH.l4,
.theme-1 .Level3LOW.l4,
.theme-1 .Level2LOW.l4,
.theme-1 .Level1LOW.l4 {
  stroke: #762a83;
}
.theme-1 .SD {
  stroke: #999999;
}
.theme-2 .RAWOPC {
  opacity: 0.5;
}
.theme-2 .RAWOPC.l0 {
  stroke: #000000;
}
.theme-2 .RAWOPC.l1 {
  stroke: #000000;
}
.theme-2 .RAWOPC.l2 {
  stroke: #000000;
}
.theme-2 .RAWOPC.l3 {
  stroke: #000000;
}
.theme-2 .RAWOPC.l4 {
  stroke: #000000;
}
.theme-2 .Level3HIGH,
.theme-2 .Level2HIGH,
.theme-2 .Level1HIGH,
.theme-2 .Level3LOW,
.theme-2 .Level2LOW,
.theme-2 .Level1LOW {
  opacity: 0.5;
}
.theme-2 .Level3HIGH.l0,
.theme-2 .Level2HIGH.l0,
.theme-2 .Level1HIGH.l0,
.theme-2 .Level3LOW.l0,
.theme-2 .Level2LOW.l0,
.theme-2 .Level1LOW.l0 {
  stroke: #000000;
}
.theme-2 .Level3HIGH.l1,
.theme-2 .Level2HIGH.l1,
.theme-2 .Level1HIGH.l1,
.theme-2 .Level3LOW.l1,
.theme-2 .Level2LOW.l1,
.theme-2 .Level1LOW.l1 {
  stroke: #000000;
}
.theme-2 .Level3HIGH.l2,
.theme-2 .Level2HIGH.l2,
.theme-2 .Level1HIGH.l2,
.theme-2 .Level3LOW.l2,
.theme-2 .Level2LOW.l2,
.theme-2 .Level1LOW.l2 {
  stroke: #000000;
}
.theme-2 .Level3HIGH.l3,
.theme-2 .Level2HIGH.l3,
.theme-2 .Level1HIGH.l3,
.theme-2 .Level3LOW.l3,
.theme-2 .Level2LOW.l3,
.theme-2 .Level1LOW.l3 {
  stroke: #000000;
}
.theme-2 .Level3HIGH.l4,
.theme-2 .Level2HIGH.l4,
.theme-2 .Level1HIGH.l4,
.theme-2 .Level3LOW.l4,
.theme-2 .Level2LOW.l4,
.theme-2 .Level1LOW.l4 {
  stroke: #000000;
}
.theme-3 .RAWOPC {
  opacity: 0.75;
}
.theme-3 .RAWOPC.l0 {
  stroke: #0595d0;
}
.theme-3 .RAWOPC.l1 {
  stroke: #ff00ff;
}
.theme-3 .RAWOPC.l2 {
  stroke: #a24bfd;
}
.theme-3 .RAWOPC.l3 {
  stroke: #b79227;
}
.theme-3 .RAWOPC.l4 {
  stroke: #00e6e6;
}
.theme-3 .Level3HIGH.l0,
.theme-3 .Level2HIGH.l0,
.theme-3 .Level1HIGH.l0,
.theme-3 .Level3LOW.l0,
.theme-3 .Level2LOW.l0,
.theme-3 .Level1LOW.l0 {
  stroke: #1158ac;
}
.theme-3 .Level3HIGH.l1,
.theme-3 .Level2HIGH.l1,
.theme-3 .Level1HIGH.l1,
.theme-3 .Level3LOW.l1,
.theme-3 .Level2LOW.l1,
.theme-3 .Level1LOW.l1 {
  stroke: #b80934;
}
.theme-3 .Level3HIGH.l2,
.theme-3 .Level2HIGH.l2,
.theme-3 .Level1HIGH.l2,
.theme-3 .Level3LOW.l2,
.theme-3 .Level2LOW.l2,
.theme-3 .Level1LOW.l2 {
  stroke: #590059;
}
.theme-3 .Level3HIGH.l3,
.theme-3 .Level2HIGH.l3,
.theme-3 .Level1HIGH.l3,
.theme-3 .Level3LOW.l3,
.theme-3 .Level2LOW.l3,
.theme-3 .Level1LOW.l3 {
  stroke: #745b14;
}
.theme-3 .Level3HIGH.l4,
.theme-3 .Level2HIGH.l4,
.theme-3 .Level1HIGH.l4,
.theme-3 .Level3LOW.l4,
.theme-3 .Level2LOW.l4,
.theme-3 .Level1LOW.l4 {
  stroke: #0f7886;
}
