.reports-chart-container {
  position: relative;
}

.ChartInfo {
  margin-left: 8px;
  margin-top: 4px;
  float: left;

  &:hover,
  &:focus {
    .ChartInfo__icon {
      color: #03a9f4;
    }
    .ChartInfo__dropdown {
      left: 0;
    }
  }
}

.ChartInfo__icon {
  cursor: pointer;

  &:before {
    font-family: icomoon;
    content: "\e900";
    color: #aeaeae;
  }
}

.ChartInfo__dropdown {
  position: absolute;
  left: -1000000em;

  width: 20em;
  font-size: 0.75em;
  background-color: #fbfbfb;
  border-bottom: 1px solid #999999;
  border-right: 1px solid #999999;
  padding: 1em;
  padding-top: 0;
}

.ChartInfo__title {
  text-transform: uppercase;
  padding-top: 1em;
  font-weight: 400;
  color: #999999;
}

.ChartInfo__value {
  padding-left: 0.75em;
}

.ChartInfo__value--code {
  font-family: monospace;
  color: #c25;
}
