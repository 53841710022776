@import "../../../styles/variables";
@import "../../../styles/helpers";

.OperatingLimitsDetails__li {
  list-style: none;
}
.OperatingLimitsDetails__clickable {
  /* Always correctly show highlighting. */
  position: relative;

  font-family: inherit;
  line-height: 1.15;
  background: none;
  font-size: 100%;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
}

.OperatingLimitsDetails__header__back {
  margin-left: 0.75em;
  padding-bottom: 0.75em;
  padding-top: 0.25em;
  display: inline-block;
}

.OperatingLimitsDetails__header__back__icon {
  padding-right: 0.25em;
}

.OperatingLimitsDetails__groupSelector {
  background-color: #f4f4f4;
  border: solid 1px #bbb;
  border-radius: 4px;
  padding-left: 10px;
  height: 31px;
  font-size: 1rem;
  padding-top: 1px;
  margin-right: $gap * 2;

  /* Can't use BEM because we're using a 3rd-party component. */
  .menu {
    overview: hidden;
    margin-left: 1em;
  }

  &:hover {
    background-color: #fcfcfc;
  }

  i {
    color: $color-gray;
  }
}

.OperatingLimitsDetails__groupSelector__selected {
  height: 23px;
  max-width: 100px;
  overflow: hidden;
  display: inline-flex;
}

.OperatingLimitsDetails__groupSelector__dropdown {
  overflow: hidden;
  height: 100%;
  padding-top: 4px;

  .icon-dropdown {
    padding-left: 0.25em;
    padding-right: 0.5em;
  }
}

.OperatingLimitsDetails__probabilitySelector {
  background-color: #f4f4f4;
  border: solid 1px #bbb;
  border-radius: 4px;
  padding-left: 10px;
  height: 31px;
  font-size: 1rem;
  padding-top: 1px;
  margin-right: $gap * 2;

  /* Can't use BEM because we're using a 3rd-party component. */
  .menu {
    overview: hidden;
    margin-left: 1em;
  }

  &:hover {
    background-color: #fcfcfc;
  }

  i {
    color: $color-gray;
  }
}

.OperatingLimitsDetails__probabilitySelector__selected {
  height: 23px;
  max-width: 120px;
  overflow: hidden;
  display: inline-flex;
}

.OperatingLimitsDetails__probabilitySelector__dropdown {
  overflow: hidden;
  height: 100%;
  padding-top: 4px;

  .icon-dropdown {
    padding-left: 0.25em;
    padding-right: 0.5em;
  }
}

.OperatingLimitsDetails__legend {
  float: left;
  margin-right: $gap;
}

.OperatingLimitsDetails__legend__column {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 1em;
  }
}

.OperatingLimitsDetails__legend__content {
  border-radius: 4px;
  border: solid 1px #cccccc;
  height: 31px;
  padding-right: $gap/3;
}

.OperatingLimitsDetails__legend__content__label {
  font-size: 0.9rem;
  line-height: 1.25;
  color: #333333;
  padding-right: 0.4em;
  padding-bottom: 0.4em;
  position: relative;
  cursor: pointer;
  border: none;
  background: transparent;

  &:focus {
    border: none;
    outline: none;
  }

  &:focus:after {
    border: solid 1px #000000;
    content: "";

    width: calc(1.5em - 1px);
    height: 1.5em;

    border-radius: 4px;

    display: inline-block;
    position: absolute;
    margin-left: $gap/2;
    left: $gap/2;
    top: 5px;

    box-sizing: content-box;
  }

  &:before {
    content: "";

    width: 1.5em;
    height: 1.5em;

    border-radius: 3px;
    margin-left: $gap/2;
    margin-right: $gap/4;

    display: inline-block;
    position: relative;
    top: 5px;
  }

  &[data-level="1"]:before {
    background-color: #7fcdbb;
    border: solid #7fcdbb;
  }

  &[data-level="2"]:before {
    background-color: #8856a7;
    border: solid #8856a7;
  }

  &[data-level="3"]:before {
    background-color: #ffcc66;
    border: solid #ffcc66;
  }

  &[data-level="4"]:before {
    background-color: #d0147e;
    border: solid #d0147e;
  }

  &[data-level="5"]:before {
    background-color: #253494;
    border: solid #253494;
  }

  &[data-active="false"]:before {
    background-color: transparent;
  }
}

.OperatingLimitsDetails__search {
  position: sticky;
  float: left;
  min-width: 20em;
  width: 20em;
  top: 3rem;

  min-height: calc(100vh - 3rem);
  margin-right: $gap;
  max-height: calc(100vh - 6rem); /* for position sticky */
}

.OperatingLimitsDetails__search__header {
  display: flex;
  align-items: stretch;
  text-transform: uppercase;

  .Input {
    font-size: 0.9em;
  }
}

.OperatingLimitsDetails__search__category {
  @include button-reset;

  background-color: $color-gray-lighter;
  border-bottom: 1px solid $color-gray-light;
  text-align: center;
  padding: 0.5em;
  width: 100%;

  border-right: 1px solid $color-gray-light;
  &:last-of-type {
    border-right: none;
  }

  &:hover,
  &:focus {
    background-color: $color-gray-lighter;
  }

  &[data-selected="true"] {
    background-color: $color-white-dark;
    border-bottom: none;

    &:hover,
    &:focus {
      background-color: $color-white-dark;
    }
  }
}

.OperatingLimitsDetails__search__count {
  padding-top: 0.5em;
  padding-left: 1em;
  font-size: 0.8em;
  color: $color-gray;
}

.OperatingLimitsDetails__search__input {
  margin: 0.5em;
}

.OperatingLimitsDetails__search__result {
  color: #5b616b;

  &:hover,
  &:focus {
    background-color: #e1f3f8;
  }
}

.OperatingLimitsDetails__search__result--selected {
  background-color: $color-gold-lightest;

  &:hover,
  &:focus {
    background-color: $color-gold-lighter;
  }
}

.OperatingLimitsDetails__search__resultDescription {
  text-transform: lowercase;
}

// .OperatingLimitsDetails__search__resultName {
//   text-transform: lowercase;
//   opacity: 0.85;
// }

.OperatingLimitsDetails__variableSelector {
  overflow-x: hidden; /* IE11 fix */
  overflow-y: auto;
  padding: 0;
}

.OperatingLimitsDetails__variableSelector__entry--searched {
  background-color: $color-gold-lightest;

  &:hover,
  &:focus {
    background-color: $color-gold-lighter;
  }
}

.OperatingLimitsDetails__BarChart {
  padding-left: 4em;
  background-color: $color-white;
  height: 240px;

  // minus the selected range, what is the color?
  // .BarChart__chart {
  //   .BarChart__plot {
  //     background-color: rgba($color-aqua-lightest, 0.5);
  //   }
  // }
}

.OperatingLimitsDetails__OverviewChart {
  border: 1px solid #dbdbdb;
  padding-left: 0;
}

.OperatingLimitsDetails__OverviewChart--right {
  margin-right: 0.5em;
  width: 35%;
}

.OperatingLimitsDetails__OverviewChart__title {
  border-bottom: none;
}

.OperatingLimitsDetails__stats__info {
  color: $color-gray-light;
}

/* heavy handed style fix, only used to make the label slightly prettier.*/
.OperatingLimitsDetails__stats__info__label {
  width: 12em !important;
}

.OperatingLimitsDetails__stat {
  font-size: 12px;
  min-width: 8em;

  &:last-child {
    margin-right: 0;
  }
}

.OperatingLimitsDetails__stat__value {
  font-size: 1.25em;
  padding: 0.1em;
  float: left;
  line-height: 0.65em;
  position: relative;
  top: 2px;
}

.OperatingLimitsDetails__stat__label {
  font-size: 0.8em;
  line-height: 1.1;
  display: block;
}

.OperatingLimitsDetails__stat__label--drop {
  &:before {
    /* content: '\A'; */
    /* white-space: pre; */
  }
}

.OperatingLimitsDetails__mainColumn {
  width: 100%;
}

.OperatingLimitsDetails__variable {
  position: relative;
  width: 100%;
  list-style: none;
  margin-bottom: 1em;
  box-sizing: border-box;
}

.OperatingLimitsDetails__variable__title {
  padding: 0.5em 1em;
  cursor: default;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  margin-top: 0.1em;

  &:first-child {
    margin-left: 2em;
  }
}

.OperatingLimitsDetails__variable__description {
  &:before {
    content: "|";
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

.OperatingLimitsDetails__variable__anomalyCheckbox {
  margin-left: auto;
  font-size: 14px;
}

.OperatingLimitsDetails__variable__anomalyCheckbox--active {
  color: #0071bc;
}

.OperatingLimitsDetails__variable__anomalyCheckbox:checked
  + .OperatingLimitsDetails__variable__anomalyLabel {
  &:before {
    color: #489fd3;
    content: "\f205";
  }
}

.OperatingLimitsDetails__variable__details {
  border-top: solid 1px $color-gray-lighter;
  // border-bottom: solid 1px $color-gray-light;
  position: relative;
  background-color: $color-white;

  height: calc(230px + 0.75em); /* base size for charts. */
  max-height: 0;

  /* Account for CSS shenanigans with box sizing.
     * See https://stackoverflow.com/questions/20827367/why-doesn-t-height0-hide-my-padded-div-even-with-box-sizingborder-box */
  overflow: hidden;
  &:before,
  &:after {
    content: "";
    display: block;
  }
}

/* Copied from info-icon. Maybe this should be a general component, but I don't
   *think* so, at least not at the moment. */
.OperatingLimitsDetails__probability__label {
  position: absolute;

  left: -10000em;
  top: auto;
  overflow: hidden;
  height: 1px;
  width: 1px;

  margin: 0;
  border-radius: 4px;
  padding: 0.2em 0.35em;

  color: #ffffff;
  font-size: 0.9em;
  background-color: #222222;

  /* Prevent "stickyness" -- that is, buttons overlapping each other or extending
     * past their expected bounds. */
  pointer-events: none;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: -0.6em;
    left: calc(70% - 0.6em);

    border-left: 0.6em solid transparent;
    border-right: 0.6em solid transparent;
    border-bottom: 0.6em solid #222222;
  }
}

.OperatingLimitsDetails__probability__icon {
  padding-right: 0.25em;
}

.OperatingLimitsDetails__insights {
  width: 62%;
  margin-left: 5em;
  margin-top: 3em;
}

.OperatingLimitsDetails__insights__label,
.OperatingLimitsDetails__insights__insight,
.OperatingLimitsDetails__insights__value {
  border-bottom: 1px solid $color-gray-lighter;
  font-size: 0.8em;
}

.OperatingLimitsDetails__insights__insight,
.OperatingLimitsDetails__insights__value {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 0.5em;
}

.OperatingLimitsDetails__insights__row {
  &:nth-child(even) {
    background-color: $color-aqua-lightest;
  }
}

// .OperatingLimitsDetails__variable__details--animated {
//   transition: max-height 0.25s ease; /* Gets disabled in IE */
// }

.OperatingLimitsDetails__variable__details--expanded {
  max-height: calc(230px + 2em); /* base size for charts. */
  background-color: rgba($color-aqua-lightest, 0.5);
  margin-bottom: 0.05em;

  .OperatingLimitsDetails__BarChart {
    background-color: transparent;

    .BarChart__title {
      border-bottom: none;
      padding-left: 2em;
    }
  }
}

.OperatingLimitsDetails__level {
  position: relative;
}

.OperatingLimitsDetails__viewSelector {
  list-style: none;
  padding-left: 0;

  display: flex;
  justify-content: center;

  position: absolute;
  z-index: 1; /* This prevents an overlap with the chart date selector, be careful removing. */
  right: 4em;
  top: 0.25em;
}

.OperatingLimitsDetails__viewSelector__option {
  border: solid 1px $color-gray-lighter;
  background-color: $color-white;

  padding-right: 0.5em;
  padding-left: 0.5em;
  margin: 0.1em;
  z-index: 1000;

  &:hover {
    background-color: $color-white-darkest;
    border-color: $color-blue;
  }

  cursor: pointer;
  &[data-selected="true"] {
    background-color: $color-white;
    border-color: $color-blue;
    color: $color-blue;
    cursor: default;
  }
}

/* Center */
.OperatingLimitsDetails__columnLoading {
  position: absolute;

  top: 0;
  height: 100%;
  display: flex;
  padding-top: 0;
  align-items: center;
  justify-content: center;
}

.OperatingLimitsDetails__level {
  &:hover,
  &:focus {
    outline-color: $color-blue;
    outline-width: thin;
    outline-style: solid;
  }
}

.OperatingLimitsDetails__StatusSeries__item {
  justify-content: left;
  align-items: center;
  display: flex;

  min-width: 100%;
  width: 100%;

  &:hover,
  &:focus {
    color: $color-blue;
    background-color: $color-aqua-lightest;
    outline: none;
  }
}

.OperatingLimitsDetails__StatusSeries__item--active {
  background-color: $color-aqua-lightest;
}

.OperatingLimitsDetails__StatusSeries__label {
  margin-right: 1.5em;
  text-align: right;
  min-width: 4em;
  font-size: 0.9em;
}

.OperatingLimitsDetails__StatusSeries__chart {
  flex-basis: 100%;
  padding-top: 0.5em;
  padding-right: 1em;
}

.OperatingLimitsDetails__keySelect {
  position: absolute;
  left: 0;
  top: -1.75em;
}

/* I'm preaching to the choir, everyone reading this already knows this. But you
 * could switch this to not be display: table, and use flexbox instead. If you
 * wanted to. It's a thing you could optionally do. In this specific case there
 * may not be much point though, table seems to be displaying OK. See
 * FaultTreeOverviewItem.scss if that's a path you want to pursue. */
.OperatingLimitsDetails__insights {
  position: relative;
  margin: 1em;
  margin-left: 4em;
}

.OperatingLimitsDetails__insights__label {
  padding-bottom: 0.5em;
  padding-right: $gap/2;
  text-align: end;

  &:first-child {
    text-align: left;
  }
}

.OperatingLimitsDetails__insights__row {
  padding: 0.5em;
}

.OperatingLimitsDetails__insights__value {
  text-align: right;
  padding-right: 0.5em;
}

.OperatingLimitsDetails__insightsColumn {
  display: flex;
  background-color: $color-aqua-lightest;
  padding-bottom: 1em;
}

.OperatingLimitsDetails__insightHeader {
  font-weight: bold;
  padding-top: 1em;
  padding-bottom: 0.75em;
}

.OperatingLimitsDetails__insight {
  width: 25em;
  line-height: 1.5;
}

.OperatingLimitsDetails__insightValue {
  float: right;
}

/* Quick fixes for testing purposes */
.OperatingLimitsDetails__StaticTrendChart {
  border: none;

  /* Line up the chart with the labels below.
     * TODO Find a more robust way to do this. */
  margin-left: 70px;
  margin-right: 53px;
  stroke-width: 1.5px;
}

.ChartInfo {
  position: absolute;
  left: -4em;
  top: -3.9em;

  &:hover,
  &:focus {
    .ChartInfo__dropdown {
      left: 0;
    }
  }
}

.ChartInfo__icon {
  &:before {
    font-family: icomoon;
    content: "\E900";
    color: #aeaeae;
  }
}

.ChartInfo__dropdown {
  position: absolute;
  z-index: 1000000;
  left: -1000000em;

  width: 45em;

  font-size: 0.9em;
  background-color: #ffffff;
  border-bottom: 1px solid #999999;
  border-right: 1px solid #999999;
  padding: 1em;
  padding-top: 0;
}

.ChartInfo__title {
  text-transform: uppercase;
  padding-top: 1em;
  font-weight: 400;
  color: #999999;
}

.ChartInfo__value {
  padding-left: 0.75em;
}

.OperatingLimitsDetails__variable--hideAnomalies {
  .Level3HIGH,
  .Level2HIGH,
  .Level1HIGH,
  .Level3LOW,
  .Level2LOW,
  .Level1LOW {
    stroke: #0595d0;
  }
}

.OperatingLimitsDetails__StatusSeries__chart {
  padding-right: 50px;
}

.OperatingLimitsDetails__StatusSeries__plot {
  /* Account for canvas margin in height */
  height: 18px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.OperatingLimitsDetails__BarChart {
  .datapoint rect {
    &[data-key="Overview Count"] {
      fill: #41b6c4;
    }
    &[data-key="Count"] {
      fill: #308dc9;
    }

    &[data-key="Amount"] {
      fill: #308dc9;
    }
  }
}

.OperatingLimitsDetails__loadMore {
  @include button-reset;

  margin-left: auto;
  margin-right: auto;
  border: solid 1px #090909;
  display: block;
  padding-left: 0.25em;
  padding-right: 0.25em;
  border-radius: 4px;

  &:hover {
    background-color: #fcfcfc;
  }
}

.OperatingLimitsDetails__variableSelector__loadMore {
  text-align: center;
}

.OperatingLimitsDetails__variableSelector__loadMore__btn {
  @include button-reset;
  color: #5b616b;

  padding-top: 0.5em;
  font-size: 0.9em;

  &:hover {
    color: #1e70bf;
  }
}

.OperatingLimitsDetails__probabilitySwitch {
  padding-left: 1em;
  padding-right: 1em;

  color: #777777;
  border-bottom: solid 2px #777777;
}

.OperatingLimitsDetails__probabilitySwitch[data-selected="true"] {
  color: #ff9000;
  border-bottom: solid 2px #ff9000;
}

.OperatingLimitsDetails__probabilitySwitch__button {
  @include button-reset;
  background-color: transparent;
}

.OperatingLimitsDetails__insights__icon {
  background: none;

  &:focus,
  &:hover {
    .OperatingLimitsDetails__insights__icon__label {
      overflow: visible;
      display: inline-block;
      top: calc(50% + 0.1em);
      transform: translateY(-50%);
      margin: 0;
      margin-left: 1.5em;
      height: auto;
      min-height: 1.5em;
      min-width: 2em;
      width: 25em;
      width: max-content;
      max-width: 25em;
      padding: 0.2em 0.35em;
      white-space: normal;

      &:before {
        display: block;
        top: calc(50% - 0.66em);
        left: -1.1em;
        width: 0.85em;
        height: 0.6em;
        border-top: 0.65em solid transparent;
        border-bottom: 0.65em solid transparent;
        border-right: 0.65em solid #222222;
      }
    }
  }
}
