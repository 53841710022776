@import "../../../styles/variables.scss";

.Manager__entity {
  background-color: white;
  border: 1px solid #dbdbdb;

  margin-bottom: 10px;
}

.Manager__entity__saving {
  margin-left: auto; /* right align icons */
  margin-right: 0;
}

.Manager__entity__saving {
  // border: solid transparent 1px;
  margin: 0.7em 0.6em;
}

.Manager__entity__label {
  padding-left: 0.4em;
  padding-top: 0.35em;

  color: #858585;
  font-size: 0.95em;
  font-style: italic;
}

/* Would like to reduce this code reset in the future */
.Manager__entityEdit__label {
  color: #000000;
  text-transform: none;
  padding-left: 0.2em;
  letter-spacing: 0;
}

.Manager__entityEdit__input {
  width: calc(100% - 1em);
  margin-right: 1em;
}

.Manager__entityEdit__controls {
  justify-content: space-between;
  padding-top: 0.5em;
  display: flex;
  width: 100%;
}

.Manager__saveMessage {
  background-color: $color-green;
  border-top: 1px solid #dbdbdb;
  color: $color-white;

  padding: 0.2em;
  text-align: center;
}

.Manager__saveMessage--error {
  background-color: $color-red-darkest;
}

.Manager__EmptyMessage {
  border: none;
}
