/* Used primarily for dropdowns, this works with a polyfill of focus-within to
 * allow styling parent selectors based on whether they or their children are
 * focused/hovered. */
@mixin focus-within {
  &:hover,
  &:focus {
    @content;
  }

  /* Needs to be split, since browsers will invalidate an entire set of selectors
   * if one is considered invalid */
  &:focus-within {
    @content;
  }

  /* NOTE: I still need to actually include the polyfill. */
  &.ally-focus-within {
    @content;
  }
}

/* Reset button styles across all browsers. */
@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: inherit;
  cursor: pointer;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover,
  &:focus {
    background: inherit;
  }

  &:focus {
    outline: inherit;
  }
}
