.SeriesContainer_Inactive {
  background-color: #d9d9d9;
}

.SeriesContainer_Active {
  background-color: #0071bc;
}

.SeriesContainer_Error {
  background-color: #de0b07;
}

.SeriesContainer_Processing {
  background-color: #f2b7ff;
}

.SeriesContainer_Missing {
  background-color: #d9d9d9;
}

.SeriesContainer_ActiveShutdown {
  background: repeating-linear-gradient(
    135deg,
    #0071bc 0px,
    #0071bc 5px,
    #999999 5px,
    #999999 10px
  );
}

.SeriesContainer_InactiveShutdown {
  background: repeating-linear-gradient(
    135deg,
    #d9d9d9,
    0px,
    #d9d9d9,
    5px,
    #999999 5px,
    #999999 10px
  );
}

.SeriesContainer_Shutdown {
  background: repeating-linear-gradient(
    135deg,
    #fff,
    0px,
    #fff,
    5px,
    #333 5px,
    #333 10px
  );
}

// these should match aria-colors.ts
.SeriesContainer_NoDrift {
  background-color: rgb(136,196,37);
}
.SeriesContainer_ModerateDrift {
  background-color: rgb(233,127,2);
}
.SeriesContainer_MajorDrift {
  background-color: rgb(194,26,1);
} 