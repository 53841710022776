/* copied from globals.css but without tailwind */
:root {
  --ft-active: 0 113 188;

  --anom0: 136 196 37;
  --anom1: 248 202 0;
  --anom2: 233 127 2;
  --anom3: 194 26 1;

  --background: 0 0% 95%;
  --foreground: 222.2 47.4% 11.2%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;

  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;

  --primary-compat: 222.2 47.4% 11.2%;
  --primary-compat-foreground: 210 40% 98%;

  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;

  --ring: 215 20.2% 65.1%;

  --radius: 0.5rem;

  /* chart related colors  */
  --chart-bg: white;
  --chart-line-color: #333333;
  --chart-outline: black;
}
