@import "./variables.scss";
@import "./helpers.scss";

/* Base */

/* We should be using the same font pretty much everywhere. */
button,
input,
optgroup,
select,
textarea,
svg {
  font-family: "Open Sans", sans-serif;
}

.container-fluid {
  padding: 0;
  min-width: 100%;

  /* Doing a manual calculation for view height is
   * not ideal, but it's a very minor dependency
   * that's easy to update. I'll look into doing something
   * a bit more resliant. I just haven't thought about
   * what the right strategy is yet. */
  min-height: calc(100vh - 31rem);
}

.DIN {
  font-family: "DINRegular";
}

/* Fonts */

@font-face {
  font-family: "DINBold";
  src: url("./fonts/DINWeb-Bold.eot");
  src: url("./fonts/DINWeb-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/DINWeb-Bold.woff") format("woff"),
    url("./fonts/DINComp-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DINLight";
  src: url("./fonts/DINWeb-Light.eot");
  src: url("./fonts/DINWeb-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/DINWeb-Light.woff") format("woff"),
    url("./fonts/DINComp-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DINRegular";
  src: url("./fonts/DINWeb.eot");
  src: url("./fonts/DINWeb.eot?#iefix") format("embedded-opentype"),
    url("./fonts/DINWeb.woff") format("woff"),
    url("./fonts/DINComp.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url("./fonts/g_open_sans.woff") format("woff");
}

@font-face {
  font-family: "Telex";
  font-style: normal;
  font-weight: 400;
  src: local("Telex-Regular"), url("./fonts/g_telex.woff") format("woff");
}

@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot");
  src: url("./fonts/icomoon.eot?#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.woff") format("woff"),
    url("./fonts/icomoon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Animations */
.fend-fadein {
  position: relative;

  /* This might seem weird to you, but Chrome has a fun bug that's been around for
   * 4 years that makes text blur whenever a CSS animation of opacity happens.
   *
   * - https://bugs.chromium.org/p/chromium/issues/detail?id=521364
   *
   * Until they get around to fixing it, we can't mess with opacity values on
   * containers without sacrificing text quality. The alternative is that we
   * make an overlay and transition it's opacity to zero, rather than
   * transitioning the opacity of the container itself to 1. */
  &:before {
    content: " ";
    background-color: $color-gray-lightest;

    z-index: 1000;
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    -webkit-animation: fend-fadein 0.4s forwards;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 0s;

    animation: fend-fadein 0.4s forwards;
    animation-iteration-count: 1;
    animation-delay: 0s;
  }
}

@-webkit-keyframes fend-fadein {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  99% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    /* remove element */
    transform: translateX(-1000%);
  }
}

@keyframes seconds {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Fault Trees */

// Chart
.staticTrendChart {
  border: 1px solid $color-gray-lighter;
  margin: $gap * 2 $gap;
  border-radius: 3px;
}

.faultTree-statusSeries-item-wrapper.selected {
  .faultTree-statusSeries-item-status {
    border: solid 2px #000000;
  }
}

.faultTree-statusSeries-item-status.processing {
  background-color: #f2b7ff;
  border: solid 1px #f2b7ff;
}

.faultTree-statusSeries-item-status-inner.active {
  background-color: #0071bc;
  border: solid 1px #0071bc;
  color: #ffffff;
}

// forms

// Page Actions

//Date Selector

// .dateSelectors {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   margin-top: $gap;
// }

// .dateSelectors-element {
//   margin-right: $gap;
//   margin-top: 1.8rem;

//   &.dateSelectors-quickdateselector {
//     margin-top: 0;
//     margin-left: 0;
//   }
// }

// .dateSelectors-selectorsLabel {
//   text-align: center;
//   font-size: 0.9rem;
//   color: $color-gray;
//   padding-top: 3px;
//   padding-bottom: 3px;
// }

// .dateSelectors-quickdateselector-item {
//   @include button-reset;

//   float: left;
//   border-right: solid 1px #bbb;
//   border-top: solid 1px #bbb;
//   border-bottom: solid 1px #bbb;
//   text-align: center;
//   width: 45px;
//   background-color: #f5f5f5;
//   font-size: 13px;
//   color: #333333;
//   cursor: pointer;
//   padding-top: 5px;
//   padding-bottom: 5px;
//   margin-bottom: 3px;
// }

// .dateSelectors-quickdateselector-item:focus {
//   border-color: #0071bc;
//   box-sizing: border-box;
//   border-left: solid 1px #0071bc;
//   margin-left: -1px;
//   width: 46px;
// }

// .dateSelectors-quickdateselector-item.first:focus {
//   border-left: solid 1px #0071bc;
//   margin-left: 0;
//   width: 45px;
// }

// .dateSelectors-quickdateselector-item:hover {
//   background-color: #fcfcfc;
// }

// .dateSelectors-quickdateselector-item.first {
//   border-left: solid 1px #bbb;
//   border-top-left-radius: 4px;
//   border-bottom-left-radius: 4px;
// }

// .dateSelectors-quickdateselector-item.last {
//   border-top-right-radius: 4px;
//   border-bottom-right-radius: 4px;
// }

// .dateSelectors-quickdateselector-item.selected {
//   background-color: #ddd;
// }

// .dateSelectors-quickdateselector-item.disabled {
//   background-color: #ffffff;
//   cursor: default;
//   color: #909090;
// }

// .dateSelectors-quickdateselector-item.disabled:hover {
//   background-color: #ffffff;
// }

// .dateSelectors-calender-item {
//   text-align: center;
//   width: 210px;
//   margin-right: $gap;
// }

// .dateSelectors-arrows {
//   margin-top: 1.8rem;
//   margin-right: $gap/2;
// }

// .dateSelectors-arrows-item {
//   border-right: solid 1px #bbb;
//   border-top: solid 1px #bbb;
//   border-bottom: solid 1px #bbb;
//   background-color: #f4f4f4;
//   color: $color-gray;
//   float: left;
//   width: 30px;
//   text-align: center;
//   cursor: pointer;
//   padding-top: 5px;
//   padding-bottom: 5px;
// }

// .dateSelectors-arrows-item:hover {
//   background-color: #fcfcfc;
// }

// .dateSelectors-arrows-item.left {
//   border-left: solid 1px #bbb;
//   border-bottom-left-radius: 4px;
//   border-top-left-radius: 4px;
// }

// .dateSelectors-arrows-item.right {
//   border-bottom-right-radius: 4px;
//   border-top-right-radius: 4px;
// }

// .dateSelectors-arrows-item.right[disabled] {
//   color: $color-gray-light;
//   cursor: auto;

//   &:hover {
//     background-color: #f4f4f4;
//   }
// }

// .dateSelectors-calender-item {
//   cursor: pointer;
//   position: relative;
// }

// .dateSelectors-calender-item input {
//   border: solid 1px #cccccc;
//   border-radius: 4px;
//   width: 100%;
//   background-color: #f5f5f5;
//   padding-left: 30px;
//   cursor: pointer;
//   padding-top: 7px;
//   padding-bottom: 5px;
//   font-size: 15px;

//   &:hover {
//     background-color: #fcfcfc;
//   }
// }

// .DayPickerInput {
//   input {
//     border: 1px solid #5b616b;
//   }

//   &::before {
//     font-family: 'Font Awesome 5 Free';
//     font-weight: 900;
//     content: '\f133';
//     float: left;
//     position: relative;
//     display: none;
//   }

//   &::after {
//     font-family: 'Font Awesome 5 Free';
//     font-weight: 900;
//     content: '\f078';
//     float: right;
//     position: relative;
//     display: none;
//   }
// }

//Empty message component
.EmptyMessage {
  text-align: center;
  background: $color-gray-lightest;
  padding: $gap * 2 $gap;
  border-radius: 3px;
  height: 100%;
  font-size: 1.5em;
  color: $color-gray-medium;
  border: 0.5px solid $color-gray-lighter;
  padding-top: 8%;

  p {
    margin-bottom: $gap;
  }

  strong {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.85em;
    letter-spacing: -0.01em;

    /* TODO is there a reason for this? Keep an eye open, but it shouldn't affect anything. */
    /* padding: 0 0.2em; */
  }

  i {
    font-size: 1em;
  }
}

.EmptyMessage__icon {
  margin-right: 0.5em;
}

/* ------- POLYFILLS & extensions ------------- */

/* Pure CSS icons */
// .nm {
//   -moz-osx-font-smoothing: grayscale;
//   -webkit-font-smoothing: antialiased;
//   display: inline-block;
//   font-style: normal;
//   font-variant: normal;
//   text-rendering: auto;
//   line-height: 1;
// }

// .nm-m-box {
//   &:before {
//     vertical-align: top;
//     display: inline-block;
//     content: 'M';
//     color: white;
//     border-radius: 0.3em;
//     background-color: #5b616b;
//     width: 1.1em;
//     height: 1em;
//     font-size: 0.8em;
//     padding: 0.1em;
//     font-family: sans-serif;
//   }
// }
