.SIDEBAR {
  z-index: 0;

  &-SINGLE {
    top: 3rem;

    min-height: calc(100vh - 3rem);
    height: calc(100vh - 3rem);
  }

  &-SINGLE-ZERO {
    top: 0;

    min-height: 100vh;
    height: 100vh;
  }

  &-DOUBLE {
    top: 6rem;

    min-height: calc(100vh - 6rem);
    height: calc(100vh - 6rem);
  }
  position: sticky;
  float: left;
  overflow-y: auto;
  border-top: 1px solid #e2e8f0;
}

.full-height-minus-nav {
  min-height: calc(100vh - 3rem);
  height: calc(100vh - 3rem);
}

.MAX-W-SIDEBAR {
  width: calc(100vw - 24rem);
  max-width: calc(100vw - 24rem);
}

@keyframes expandSidebar {
  from {
    width: 0;
    transform: translateX(-24rem);
  }
  to {
    width: 24rem;
    transform: translateX(0);
  }
}
.expand-sidebar {
  animation: expandSidebar 0.5s ease-in-out forwards;
}

@keyframes collapseSidebar {
  from {
    width: 24rem;
    transform: translateX(0);
  }
  to {
    width: 0;
    transform: translateX(-24rem);
  }
}
.collapse-sidebar {
  animation: collapseSidebar 0.5s ease-in-out forwards;
}


@keyframes expandSidebarHandle {
  from {
    left: 0;
  }
  to {
    left: 24rem;
  }
}
.expand-sidebar-handle {
  animation: expandSidebarHandle 0.5s ease-in-out forwards;
}

@keyframes collapseSidebarHandle {
  from {
    left: 24rem;
  }
  to {
    left: 0;
  }
}
.collapse-sidebar-handle {
  animation: collapseSidebarHandle 0.5s ease-in-out forwards;
}
