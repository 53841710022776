.exprRef {
  display: inline-block;
  padding: 0 0.4em;
  border-radius: 0.6em;
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 3%,
    var(--indigo-3) 3%,
    var(--indigo-3) 97%,
    transparent 97%,
    transparent 100%
  );
  font-family: monospace;
  position: relative;
  /* bottom: 0.1em; */
  color: var(--indigo-11);
  cursor: pointer;
  font-size: 1.05em;
  &:hover {
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent 3%,
      var(--indigo-5) 3%,
      var(--indigo-5) 97%,
      transparent 97%,
      transparent 100%
    );
  }
  &.selected {
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent 3%,
      var(--indigo-11) 3%,
      var(--indigo-11) 97%,
      transparent 97%,
      transparent 100%
    );
    color: var(--indigo-3);
  }
  &::before {
    content: '"';
  }
  &::after {
    content: '"';
  }
}
