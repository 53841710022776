.MainLoader--statusSeries {
  color: #555555;
  text-align: left;
  padding-top: 0;

  font-size: 0.9em;
  display: flex;
  margin-bottom: 0.25em;
  align-items: center;
  justify-content: center;
}

$chart-height: 24px;

.StatusSeries {
  position: relative; /* for absolute-positioned children */
}

/* This is ported from fault-trees. Should it exist? */
.StatusSeries--wrapper {
  height: 100%;
}

.StatusSeries__chart {
  width: 100%;

  /* Account for canvas margin in height */
  height: 24px;
}

.StatusSeries__dates {
  position: absolute;
  width: 100%;
  height: 1em;
}

.StatusSeries__dates--below {
  top: $chart-height;
}

.StatusSeries__dates--above {
  top: -2em;
}

.StatusSeries__date {
  color: #555555;
  display: inline-block;
  text-align: center;
  position: absolute;
  font-size: 0.8rem;
}
