// @tailwind base;
// @tailwind components;
// @tailwind utilities;
@import "../../../styles/variables.scss"; /* colors are in here. */

.NotificationsManager__title {
  padding-top: 1.2em;

  h2 {
    margin: 0;
    padding-right: 0.8em;
  }
}

.NotificationsManager__createButton {
  width: auto;
}

.NotificationsManager__search {
  width: 100%;
  margin: 0 0 1em;
  border-color: #ccc;
  font-weight: 600;
  font-size: 0.9em;
}

.NotificationsManager__entity__header {
  border-bottom: none;
  justify-content: space-between;
  display: flex;
}

.NotificationsManager__wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 55em;
}

.NotificationsManager__badges {
  max-width: 80%;
  line-height: 1.6em;
}

.NotificationsManager__badge {
  font-size: 0.9rem;
  border-radius: 1rem;
  background-color: #ffffff;
  border: 1px solid #cacaca;
  margin-right: 0.4em;
  padding: 0 0.8em;
}

.NotificationsManager__badge--Custom {
  background-color: #c4eeff;
  border-color: darken(#c4eeff, 20%);
}

.NotificationsManager__badge--Pattern {
  background-color: #f5bd98;
  border-color: darken(#f5bd98, 20%);
}

.NotificationsManager__badge--OperatingLimit {
  background-color: #cef0e7;
  border-color: darken(#cef0ec, 20%);
}

.NotificationsManager__badge--DRA {
  background-color: #fff8d3;
  border-color: darken(#fffbe6, 20%);
}

.NotificationsManager__badge--FaultTreeNode {
  background-color: #ffe9e5;
  border-color: darken(#ffe9e5, 20%);
}

.NotificationsManager__badge--Slope {
  background-color: #dcd5f9;
  border-color: darken(#dcd5f9, 20%);
}

.NotificationsManager__Input--colored {
  color: #0071bc;
}

.NotificationsManager__label__info {
  background-color: transparent;
}

.NotificationsManager__label__info__label {
  min-width: 25em;
}

.NotificationsManager__entity--inactive {
  background-color: #f5f5f5;
}

.NotificationsManager__entity__saving {
  font-size: 1.2em;
  margin: 0.75em 0.6em;
}

.NotificationsManager__label {
  margin: 0.7em;
  font-weight: 500;
}

.NotificationsManager__value {
  font-size: 1em;
}

.NotificationsManager__value--expression {
  font-size: 1.2em;
  color: #0071bc;
  background-color: #e6f0f3;
  padding: 0 5px;
  border-radius: 4px;
  margin-top: 0.5rem;
  font-family: monospace;
  min-height: 2em;
}

.NotificationsManager__entity__body {
  display: flex;
  position: relative;
  line-height: 1.6em;
}

.NotificationsManager__entity__navigate {
  position: absolute;
  margin-top: auto;
  right: 0.4em;
  top: 0;
}

.NotificationsManager__entity__navigate__label {
  min-width: 6em;
}

.NotificationsManager__entity__footer {
  justify-content: space-between;
  border-top: 1px solid #dbdbdb;
  padding: 0.45em 0.5em;
  font-style: italic;
  margin-top: 0.5em;
  font-size: 0.9em;

  color: #757575;
  display: flex;
}

.NotificationsManager__entityEdit__title {
  font-size: 1.3em;
  margin: 0.75em 0;
}

.NotificationsManager__editForm {
  margin: 0 0.15em;
}
