/* Variables */
@import "../../../styles/variables.scss";

.SpecialtyReportManager {
  &__entity__ {
    &name {
      padding-right: 0.5em;
      font-weight: 600;
    }

    &title {
      background-color: #efefef;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      padding-left: 1rem;
      padding-right: 0.5rem;
      display: inline-block;
    }

    &value {
      padding-left: 0.25rem;
      padding-right: 1rem;
    }
  }
}

.SpecialtyReportManager__entity__toggles {
  padding-top: 0.3em;
  padding-left: 0.45em;
}

.SpecialtyReportManager__entity__toggle {
  padding-right: 2em;
  padding-left: 0.4em;
}

.SpecialtyReportManager__entity__footer {
  font-style: italic;
  color: $color-gray-medium;
  border-top: 1px solid #dbdbdb;

  display: flex;
  justify-content: space-between;
}

.SpecialtyReportManager__entity__ref {
  display: inline-block;
  padding: 0 0.8em;
  margin-right: 0.4em;
  margin-bottom: 0.4em;
  border-radius: 1rem;
  border: 1px solid #dbdbdb;
  cursor: default;
}

.SpecialtyReportManager__entity__locked {
  color: #a5a5a5;
  margin-right: 0.3em;
  margin-left: 0.1em;
  position: relative;

  &:hover {
    .SpecialtyReportManager__entity__locked__label {
      position: absolute;

      left: 50%;
      z-index: 1000;
      top: calc(100% + 0.3em);
      transform: translateX(-50%);

      margin: 0;
      height: auto;
      width: auto;
      width: 6em;
      border-radius: 4px;
      padding: 0.2em 0.35em;

      color: #ffffff;
      font-size: 0.9em;
      background-color: #222222;
      overflow: visible;

      /* Prevent "stickyness" -- that is, buttons overlapping each other or extending
       * past their expected bounds. */
      pointer-events: none;

      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: -0.8em;
        left: calc(50% - 0.6em);
        width: 0.6em;
        height: 0.8em;

        border-left: 0.6em solid transparent;
        border-right: 0.6em solid transparent;
        border-bottom: 0.6em solid #222222;
      }
    }
  }
}

.SpecialtyReportManager__entity__locked__label {
  position: absolute;
  left: -10000em;
  top: auto;

  overflow: hidden;
  height: 1px;
  width: 1px;
}

.SpecialtyReportManager__entity__label {
  margin-bottom: 0.3em;
  font-style: italic;
  color: $color-gray-medium;
}

.SpecialtyReportManager__entityColumn {
  width: 50%;
  float: left;
}

.SpecialtyReportManager__doubleColumn {
  width: 100%;
  padding-right: 0.8em;

  &:after {
    content: "";
    clear: both;
    display: block;
  }
}

.SpecialtyReportManager__entityColumn__subContent {
  font-size: 0.9em;
  padding-left: 15px;

  .Input--type-multipleselect {
    width: 150px;
    display: inline-block;
  }
}

.SpecialtyReportManager__entityEdit__groupOrder {
  height: 8em;
}

.react-time-picker,
.react-time-picker__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
