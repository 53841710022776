@import "../../../styles/variables.scss";

.OperatingLimitsManager {
  // To Do: Move button styles to a global location;

  .button--add {
    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f055";
      padding-right: $gap/2;
      font-size: $gap;
      position: relative;
      bottom: 0.08em;
    }
  }

  .button--remove {
    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00d";
      padding-right: $gap/2;
      font-size: $gap;
      position: relative;
      bottom: 0.04em;
    }
  }

  .button--calendar {
    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f133";
      padding-right: $gap/2;
      font-size: $gap;
      position: relative;
      bottom: 0.04em;
    }
  }

  .DayPickerInput {
    input {
      border: 1px solid $color-gray;
    }
  }
}

.OperatingLimitsManager__segments {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
  height: auto;
}

.OperatingLimitsManager__segment {
  display: inline;
}

.OperatingLimitsManager__limit__history {
  padding-left: $gap;
}

.OperatingLimitsManager__limit__value {
  width: 75px;
  // display: inline-block;
}

.OperatingLimitsManager__limit__date {
  display: inline-block;
}

.OperatingLimitsManager__limit__date input {
  border: solid 1px #b0b0b0;
  padding: 0.2em;
  height: 27px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.OperatingLimitsManager__limit__lastUpdated {
  padding-left: $gap;
  font-style: italic;
  color: $color-gray;
  font-size: 0.8em;
  margin-top: $gap/2;
}

.OperatingLimitsManager__saveMessage {
  background-color: $color-green;
  border-top: 1px solid #dbdbdb;
  color: $color-white;

  padding: 0.2em;
  text-align: center;
}

.OperatingLimitsManager__saveMessage--error {
  background-color: $color-red-darkest;
}

.OperatingLimitsManager__limit__label {
  padding-left: $gap;
  font-weight: 600;
}

.OperatingLimitsManager__limitEdit__label {
  padding-left: 0;
  font-weight: 600;
  color: $color-gray;
  margin-top: $gap;
  font-size: 0.9em;
}

.OperatingLimitsManager__limit__actions {
  float: right;
}

.OperatingLimitsManager__noresults {
  font-size: 1.3em;
  text-align: center;
  margin-top: 1.6em;
}

.OperatingLimitsManager__table__body,
.OperatingLimitsManager__table__head {
  > tr > th {
    color: $color-gray;
  }

  > tr > th,
  > tr > td {
    border-bottom: 1px solid $color-gray-lightest;
    font-size: 0.9em;
    padding-left: $gap;
    padding-right: $gap/2;
    padding-top: $gap/2;
    padding-bottom: $gap/2;

    &:first-child {
      border-right: 1px dotted $color-gray-light;
    }
  }
}

.OperatingLimitsManager__table {
  margin-top: $gap;
  margin-bottom: $gap/2;
  min-width: 40%;

  &__row {
    background-color: $color-white-dark;

    &:nth-child(even) {
      background-color: $color-gray-lightest;
    }
  }

  &__edit {
    margin-top: 1em;
    min-width: 180px;

    > th,
    > td {
      border-bottom: 1px solid $color-gray-lightest;
      font-size: 0.9em;
      padding-left: $gap;
      padding-top: $gap/2;
      padding-bottom: $gap/2;
      font-weight: normal;
    }

    Input {
      min-height: 2.2em;
      width: auto;
    }
  }
}
