.chart-columns li {
  display: inline;
  float: left;
  width: 48.80952381%;
  margin: 0 0.5952381%;
}
.chart-list-header {
  border-bottom: 1px solid #e5e5e5;
  height: 30px;
  background-color: #ffffff;
  line-height: 1.85rem;
  font-size: 0.8rem;
  color: #555555;
  margin-bottom: 0.5rem;
  position: relative;
}
.chart-list-header .header-title {
  position: absolute;
  right: 317px;
  width: auto;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.03rem;
}
.header-actions {
  opacity: 0.6;
  cursor: pointer;
  color: #969696;
  padding-top: 5px;
  padding-bottom: 6px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.header-actions:hover {
  border-right: 1px solid #d9d9d9;
  background: #fafafa;
  color: #757575;
  opacity: 1;
}
.expand-action {
  transform: rotate(-45deg);
}
.expand-action:hover {
  background-color: none;
}
.chart-actions {
  display: block;
  min-height: 32px;
}

.chart-actions .btn-label,
.chart-actions .btn-comment {
  float: right;
}
.chart-actions__info {
  display: inline-grid;
  float: right;
  width: 84%;
}
.chart-actions__info-item {
  font-size: 12px;
  line-height: 16px;
  display: block;
  margin: 0 auto;
}

.chart-container .chart-container-header {
  height: 1.9em;
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}
.chart-container .pov-chart-container-header:hover {
  background-color: #dce6ef;
}
.chart-container .pov-chart-container-header:hover h5 {
  color: #4183c4;
}
.chart-container .pov-chart-container-header:hover .label-unit {
  background: #79a6d0;
}
.chart-container-header .label-unit {
  background: #d1d1d1;
  color: #ffffff;
  width: 50px;
  border-radius: 10px;
  float: left;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9em;
  margin-right: 0.5em;
  line-height: 1.5em;
}
.chart-title {
  float: left;
  padding-left: 6px;
  padding-top: 5px;
  font-size: 0.8em;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20.5rem;
}
.ft-chart-title {
  max-width: 100%;
}
.chart-title em {
  padding-left: 2px;
}
.chart-title h5 {
  font-size: 0.9em;
  display: inline;
}
.chart-header {
  height: 30px;
  line-height: 1.75rem;
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
}
.chart-label {
  width: 62px;
  text-align: center;
}
.chart-label .var-unit {
  font-size: 0.8rem;
  background: #dadada;
  color: #ffffff;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border-radius: 0.75rem;
  cursor: pointer;
}
.chart-title-float {
  width: 369px;
}
