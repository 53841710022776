@import "../../styles/helpers";

$red: #c21c29;

$light-blue: #6ab0de;
$dark-blue: #628196;
$blue: #3aafda;

$lighter-gray: #f9f9f9;
$light-gray: #f3f3f3;
$dark-gray: #767676;
$gray: #5b616b;
$white: #ffffff;

// NOTE:  We should probably refactor the colors used here to leverage the global colors variables.
//        Partially adding those below, this is redundant;

$color-white: #ffffff;
$color-white-dark: #fdfdfd;
$color-white-darkest: #f5f5f5;
$color-black: #000000;
$color-black-light: #212121;

$color-gray-dark: #323a45;
$color-gray: #5b616b;
$color-gray-medium: #757575;
$color-gray-light: #aeb0b5;
$color-gray-lighter: #e4e5e6;
$color-gray-lightest: #f2f2f2;

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}
.Input {
  @include border-radius(4px);
  box-sizing: border-box;
  font-size: 1em;
  padding: 0.4em 0.3em;
  margin: 0.2em;

  outline: none;
  background-color: $white;
  border: 1px solid $gray;
  min-width: 24px;

  //For elements like textareas
  resize: none;

  /* Focus within isn't supported in IE11,
     * but we can still get the benefits in Chrome/Firefox */
  &:focus,
  &:focus-within {
    border: 1px solid $blue;
  }
}

.Input--inline {
  display: inline-block;
}

.Input[disabled] {
  background-color: $light-gray;
}

.Input--type-button {
  @include border-radius(4px);
  padding: 0em 0.4em;
  width: auto;

  /* IE11 needs pointer manually set */
  cursor: pointer;

  /* Old app settings has a border here, but spec doesn't. */
  outline: none;
  background-color: $blue;
  color: $white;

  /* Why not remove the border entirely?
     * It's useful to have buttons and inputs be the same size.
     * This prevents a few jarring resizes when you're using height:auto; */
  border-color: transparent;

  &:focus,
  &:hover {
    border-color: transparent;
    background-color: $light-blue;
  }

  &:active {
    border-color: transparent;
    background-color: $dark-blue;
  }

  &[disabled] {
    cursor: not-allowed; /* IE11 needs pointer manually set */
    background-color: $gray;
  }
}

.Input--type-checkbox {
  margin-left: 0;
}

.Input--type-fancycheckbox {
  vertical-align: top;
  cursor: pointer;
  padding: 0;
  height: 0;
  width: 0;

  margin-left: 0.5em;
  margin-right: 0.5em;

  &:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    opacity: 1;
    position: absolute;
    content: "\F204";
    color: #5b616b;
    font-size: 1.3em;
  }

  &:checked:after {
    content: "\F205";
    color: #0071bc;
  }

}

.Input--type-select {
  box-sizing: content-box;
  border-style: solid;
  padding: 0.1em 0;

  /* Chrome ignores text centering in select elements normally, but the below hack
   * should work for right now. */
  text-align: center;
  text-align-last: center;
}

.Input--type-select-autosize {
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.Input__icon + .Input__label {
  margin-left: 0.3em;
}

.Input__label--inline {
  display: inline;
  padding-left: 0.3em;
}

.Input__label--header {
  margin-bottom: 0;
  margin-top: 0.5em;

  color: $gray;
  font-size: 1em;

  word-spacing: 0.2em;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}

.Input__label--hidden {
  position: absolute;
  left: -10000em;
  top: auto;

  overflow: hidden;
  height: 1px;
  width: 1px;
}

.Input__group {
  box-sizing: border-box;
  border-radius: 3px;
  background-color: $light-gray;
  padding: 0.6em;
  padding-bottom: 2.4em;
  padding-right: 1.2em;
}

.Input__labelContainer {
  display: inline-block;
  position: relative;
  font-size: 1em;
  // padding: 0.4em 0.3em;
  // margin: 0.2em;

  &:focus,
  &:hover {
    .Input__label--hidden {
      position: absolute;
      pointer-events: none;

      left: 50%;
      z-index: 1000;
      top: calc(100% + 0.3em);
      transform: translateX(-50%);

      margin: 0;
      height: auto;
      width: auto;
      border-radius: 4px;
      padding: 0.2em 0.35em;

      color: #ffffff;
      font-size: 0.9rem;
      background-color: #222222;
      overflow: visible;

      line-height: 1.3;
      padding: 0.5em;

      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: -0.6em;
        left: calc(50% - 0.6em);

        border-left: 0.6em solid transparent;
        border-right: 0.6em solid transparent;
        border-bottom: 0.6em solid #222222;
      }
    }
  }
}

/* Overrides for third-party component. Even though we're using BEM, we still
 * need to think about specificity, because the component adds its own
 * classnames onto the end of each element. */
.Input--type-multipleselect {
  padding: 0;

  .react-select__control {
    min-height: 0;
    border-style: none;

    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    height: calc(100% - 0.8em);
    margin: 0.4em 0.3em; /* Copied over from Input style */

    /* Hack fix for the lack of collapsing margins in flexbox containers. Kind of a
     * pain in the neck. See .react-select__value-container */
    margin-bottom: 0;
  }

  &.react-select--is-disabled {
    background-color: #f2f2f2;
  }

  .react-select__control--is-focused {
    border-style: none;
    box-shadow: none;
  }

  .react-select__value-container {
    padding: 0;

    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    /* #$%*X! generated class-names everywhere */
    > * {
      margin: 0;
      padding: 0;

      margin-right: 4px;
      padding-right: 6px;

      /* See above -- hack fix because flexbox doesn't collapse margins. */
      margin-bottom: 0.4em;
    }
  }

  .react-select__value-container--is-multi {
    overflow-y: auto;
    height: 100%;

    .react-select__placeholder {
      position: relative;
      transform: none;
      margin-bottom: 0;
      top: 0;
    }
  }

  .react-select__single-value,
  .react-select__placeholder {
    /* See above -- hack fix because flexbox doesn't collapse margins. */
    padding-bottom: 0.4em;
  }

  .react-select__indicator-separator {
    background-color: inherit;
  }

  .react-select__indicator {
    padding: 0;

    /* See above -- hack fix because flexbox doesn't collapse margins. */
    padding-bottom: 0.4em;
  }

  .react-select__indicators {
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .react-select__menu {
    margin: 0;

    border: 1px solid $gray;
    @include border-radius(4px);
  }

  .react-select__menu-list {
    padding: 0;
  }

  .react-select__multi-value__label {
    padding: 0;
    padding-left: 6px;
  }
}

.Input--type-search {
  width: calc(100% - 0.4em);
}

.Input__container {
  position: relative;

  /* @TODO: add IE11 polyfill. */
}

.Input__searchResults {
  position: absolute;
  z-index: 99999;
  display: none;

  list-style: none;
  padding: 0;
  margin: 0;

  max-height: 30vh;
  width: 100%;
  margin-top: -0.2em;

  overflow: hidden;
  overflow-y: auto;
  border: 1px solid $light-gray;
  border-top: none;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  box-shadow: 0 0 3px $gray;
}

.Input__searchResults--visible {
  display: block;
}

.Input__searchClose {
  @include button-reset;

  position: absolute;
  color: #595959;
  line-height: 1;
  top: 0.55em;
  right: 1em;
}

.Input__searchClose__label {
  position: absolute;
  left: -10000em;
  top: auto;
  overflow: hidden;
  height: 1px;
  width: 1px;
}

.Input__searchResult__element {
  @include button-reset;

  text-align: left;
  padding: 0.5em;
  width: 100%;
  border-bottom: solid 1px $light-gray;
  background-color: $lighter-gray;
  &:hover,
  &:focus {
    background-color: $white;
  }
}

.Input--type-info {
  position: relative;
  border: none;

  &:focus {
    border: none;
  }

  .Input__label--hidden {
    /* Info icons can be selected and copied, unlike other labels. */
    pointer-events: inherit;
  }

  &:focus,
  &:hover {
    .Input__label--hidden {
      position: absolute;

      left: 50%;
      z-index: 1000;
      top: calc(100% + 0.3em);
      transform: translateX(-50%);

      margin: 0;
      height: auto;
      width: auto;
      border-radius: 4px;
      padding: 0.2em 0.35em;

      color: #ffffff;
      font-size: 0.9rem;
      background-color: #222222;
      overflow: visible;

      line-height: 1.3;
      padding: 0.5em;

      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: -0.6em;
        left: calc(50% - 0.6em);

        border-left: 0.6em solid transparent;
        border-right: 0.6em solid transparent;
        border-bottom: 0.6em solid #222222;
      }
    }
  }
}

.Input--type-link {
  @extend .Input--type-button;
  position: relative; /* Used for hover effects */
  background-color: transparent;
  color: $color-gray;
  border: 1px solid transparent;
  box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
  line-height: 1.5;

  &:focus,
  &:hover {
    background-color: $color-white-dark;
    border: 1px solid $color-gray-medium;
    color: $color-gray;
    outline: none;
  }

  &:active {
    background-color: $color-gray-lightest;
  }

  .Input__label--hidden {
    /* Prevent "stickyness" -- that is, buttons overlapping each other or extending
         * past their expected bounds. */
    pointer-events: none;
  }

  &:focus,
  &:hover {
    .Input__label--hidden {
      position: absolute;

      left: 50%;
      z-index: 1000;
      top: calc(100% + 0.55em);
      transform: translateX(-50%);

      margin: 0;
      height: auto;
      width: auto;
      border-radius: 4px;
      padding: 0.2em 0.35em;

      color: #ffffff;
      font-size: 0.9rem;
      background-color: #222222;
      overflow: visible;

      &:before {
        content: " ";
        display: block;
        position: absolute;
        top: -0.6em;
        left: calc(50% - 0.6em);

        border-left: 0.6em solid transparent;
        border-right: 0.6em solid transparent;
        border-bottom: 0.6em solid #222222;
      }
    }
  }
}

.Input {
  &:focus,
  &:hover {
    .Input__label--tooltip {
      /* For IE, we need a non `max-content` fallback */
      width: 30em;
      width: max-content;

      max-width: 30em;
    }
  }
}
