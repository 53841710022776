.VariableInfo {
  padding: 0.25rem;
  margin-left: 0.25rem;
  margin-right: 0.2rem;
  position: relative;

  &:hover,
  &:focus {
    .VariableInfo__dropdown {
      left: 0.8em;
    }

    .VariableInfo__icon:before {
      color: #0071bc;
    }
  }
}

.VariableInfo__icon {
  vertical-align: text-bottom;

  &:before {
    font-family: icomoon;
    content: "\E900";
    color: #aeaeae;
  }
}

.VariableInfo__dropdown {
  position: absolute;
  z-index: 1000000;
  left: -1000000em;
  top: 2.1em;

  transform: translateX(-50%);
  border-radius: 0.2em;
  box-shadow: 0 0 1em #cccccc;
  border: 1px solid #99999980;

  font-size: 0.9em;
  background-color: #ffffff;
  border-bottom: 1px solid #999999;
  border-right: 1px solid #999999;
  padding: 1em;
  padding-top: 0;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: -0.6em;
    left: calc(50% - 0.6em);

    border-left: 0.6em solid transparent;
    border-right: 0.6em solid transparent;
    border-bottom: 0.6em solid #bdbdbd;
  }

  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: calc(-0.6em + 1.5px);
    left: calc(50% - 0.6em);

    border-left: 0.6em solid transparent;
    border-right: 0.6em solid transparent;
    border-bottom: 0.6em solid #ffffff;
  }
}

.VariableInfo__title {
  text-transform: uppercase;
  padding-top: 1em;
  font-weight: 400;
  color: #999999;
}

.VariableInfo__value {
  padding-left: 0.75em;
  white-space: nowrap;
}

.VariableInfo__value--code {
  font-family: monospace;
  color: #c25;
}
